import styled from 'styled-components';
import { Text } from '../../../Layouts';
import { colorCodes } from '../../../../Constants/colors';

export const InputRow = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const LabelText = styled(Text)`
    color: ${colorCodes.WHITE};
    font-size: 22px;
    margin-right: 16px;
    text-align: right;

    @media (max-width: 630px) {
        font-size: 18px;
    }

    @media (max-width: 440px) {
        font-size: 14px;
    }
`;

export const SublabelText = styled.span`
    color: ${colorCodes.WHITE};
    opacity: 0.5;
    font-size: 22px;

    @media (max-width: 630px) {
        font-size: 18px;
    }

    @media (max-width: 440px) {
        font-size: 14px;
    }
`;

export const Container = styled.div`
    margin-top: 30px;
    z-index: 2;
`;

export const AdvancedQuestionsContainer = styled.div`
    width: 100%;
    z-index: 2;
    margin-top: 30px;
`;

export const LabelWrapper = styled.div`
    width: 330px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 630px) {
        width: 170px;
    }

    @media (max-width: 430px) {
        max-width: 100px;
    }
`;

export const ShowMoreLabel = styled(Text)`
    font-size: 16px;
    color: ${colorCodes.SECONDARY};
    text-align: center;
    cursor: pointer;
    font-weight: bold;

    &:hover {
        opacity: 0.5;
    }

    transition: all 0.25s;
`;

// radio buttons
export const RadioButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    // padding-left: 45px;

    & p {
        margin-right: 20px;
    }
`;

export const RadioItem = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
    position: relative;
`;

export const RadioLabel = styled.label`
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    border: 5px solid ${colorCodes.WHITE};
    background: none;
    transition: all 0.25s;
    cursor: pointer;
`;

export const RadioButton = styled.input`
    opacity: 0;
    z-index: 1;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    background: none;
    &:hover ~ ${RadioLabel} {
        background: ${colorCodes.SECONDARY};
    }
    &:checked + ${RadioLabel} {
        background: ${colorCodes.SECONDARY};
    }
    cursor: pointer;
`;

export const RadioText = styled(Text)`
    font-size: 22px;
    color: ${colorCodes.WHITE};
`;
