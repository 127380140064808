import React from 'react';
import { SmallHeader } from '../../Layouts/SmallHeader';
import {
    HeaderContent,
    Heading,
    LeftHeaderContainer,
    HeaderRegularText,
    HeaderOverlay
} from './style';
import { TableSortingToggle } from '../../Components/TableSortingToggler';

export class CompareFundsHeader extends React.PureComponent {
    render() {
        return(
            <SmallHeader>
                <HeaderOverlay />
                <HeaderContent>
                    <Heading>Compare performance of providers</Heading>
                    <HeaderRegularText>
                        Check out the performance of the top providers below. The funds analysed are those
                        which members are invested in at least 20 years from retirement.{' '}
                    </HeaderRegularText>
        
                    <TableSortingToggle {...this.props} />
                </HeaderContent>
            </SmallHeader>
        );
        
    }
} 