import * as types from '../ActionTypes/AuthActionTypes';

export class AuthActionCreators {
    static submitEmailRequest = credentials => ({
        type: types.SUBMIT_EMAIL_REQUEST,
        meta: { credentials }
    });

    static submitEmailSuccess = response => ({
        type: types.SUBMIT_EMAIL_SUCCESS,
        payload: response
    });

    static submitEmailFailed = error => ({
        type: types.SUBMIT_EMAIL_FAILED,
        payload: error
    });

    static typeEmailAgain = () => ({
        type: types.TYPE_EMAIL_AGAIN
    });
}
