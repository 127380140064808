class Request {
    constructor({ url, data, headers, method }) {
        this.url = url;
        this.data = data;
        this.headers = headers || this.getHeaders();
        this.method = method;
    }

    getHeaders() {
        const headers = {};
        headers['Content-Type'] = 'application/json';
        headers['Access-Control-Allow-Origin'] = '*';

        return headers;
    }
}

class RequestFactory {
    request = Request;
    createRequest(requestBody) {
        const _requestBody = { ...requestBody };
        if (typeof _requestBody.data === 'undefined') delete _requestBody.data;

        return new this.request(_requestBody);
    }
}

export const requestFactory = new RequestFactory();
