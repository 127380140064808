import styled from 'styled-components';
import { colorCodes } from '../../../../Constants/colors';
import { HoverableLink } from '../../../Layouts';

export const FormContainer = styled.form`
    width: 255px;
    position: relative;
`;

export const EmailInput = styled.input`
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background-color: ${colorCodes.GREY5};
    padding-left: 16px;
    color: ${colorCodes.GREY6};
    font-size: 18px;
    border: none;
    outline: none;
    margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
    width: 100%;
`;

export const LinksContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 10px;
`;

export const Link = styled(HoverableLink)`
    color: ${colorCodes.SECONDARY};
    font-weight: bold;
    font-size: 12px;
`;

export const ErrorText = styled.div`
    margin-top: -5px;
    color: ${colorCodes.SECONDARY};
    text-align: center;
`;
