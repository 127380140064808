import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';
import PenguinLogo from '../../../Assets/Images/penguin-logo@3x.png';

// Header
export const HeaderContainer = styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding: 9px 36px 9px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
`;

// Logo
export const LogoContainer = styled.div`
    display: flex;
    align-items: center;

    & img:first-child {
        margin-right: 10px;
    }
`;

export const LogoImage = styled.div`
    height: 32px;
    width: 186px;
    border: none;
    cursor: pointer;
    background-image: url(${PenguinLogo});
    background-size: cover;
`;

// Navigation
export const NavigationContainer = styled.div`
    display: flex;

    & .link {
        margin-right: 30px;
    }

    & .link:last-child {
        margin-right: 0;
    }
`;

export const NavigationLink = styled.div`
    color: ${colorCodes.WHITE};
    font-weight: bold;

    &:hover {
        opacity: 0.75;
    }

    cursor: pointer;
    transition: all 0.25s;
`;
