import React from 'react';
import { connect } from 'react-redux';
import { EmailSubmittedEmptyState } from '../EmptyStates/EmailSubmitted';
import { AuthActionCreators } from '../../../Store/ActionCreators/AuthActionCreators';
import { ComingSoonHeader, Content, MessageTextContainer, CloseBox } from './style';
import { SubmitEmailForm } from '../Forms/SubmitEmailForm';
import SubmitEmailFormContainer from '../../Containers/FormContainers/SubmitEmailForm';
import { Icon } from '../Icons';

const SubmitEmailBlock = props => (
    <Content forModal={props.forModal} emailSent={props.isEmailSent}>
        {props.forModal && !props.isEmailSent && (
            <CloseBox onClick={props.onClose}>
                <Icon.Box size={'12x12'}>
                    <Icon.Close />
                </Icon.Box>
            </CloseBox>
        )}
        {props.isEmailSent ? (
            <EmailSubmittedEmptyState forModal={props.forModal} onAction={props.typeEmailAgain}>
                {props.forModal && (
                    <CloseBox onClick={props.onClose} color={'black'}>
                        <Icon.Box size={'12x12'}>
                            <Icon.Close />
                        </Icon.Box>
                    </CloseBox>
                )}
            </EmailSubmittedEmptyState>
        ) : (
            <>
                <ComingSoonHeader>Coming soon</ComingSoonHeader>
                <MessageTextContainer forModal={props.forModal}>
                    Want to be alerted what the best Pension Fund is for you? <br /> Leave your
                    email below and we will do the rest!
                </MessageTextContainer>
                <SubmitEmailFormContainer renderForm={SubmitEmailForm} />
            </>
        )}
    </Content>
);

const mapStateToProps = ({ auth }) => ({
    isEmailSent: auth.statuses.isEmailSent
});

const mapDispatchToProps = dispatch => ({
    typeEmailAgain: () => dispatch(AuthActionCreators.typeEmailAgain())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitEmailBlock);
