import { combineReducers } from 'redux';

import { CalculatorReducer } from './CalculatorReducer';
import { AuthReducer } from './AuthReducer';
import { ErrorReducer } from './ErrorReducer';
import { ProvidersReducer } from './ProvidersReducer';
import { ModalReducer } from './ModalReducer';

export default combineReducers({
    calculator: CalculatorReducer,
    auth: AuthReducer,
    errors: ErrorReducer,
    providers: ProvidersReducer,
    modal: ModalReducer
});
