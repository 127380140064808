export class ProviderModel {
    constructor(provider) {
        this.provider = {
            id: provider.id,
            name: provider.name,
            fundName: provider.fund_name,
            logo: provider.logo,
            oneYearPerformance: provider['1year'],
            threeYearsPerformance: provider['3year'],
            fiveYearsPerformance: provider['5year'],
            fundSize: provider.fund_size,
            datePerformance: provider.date_performance,
            link: provider.link,
            createdAt: provider.created_at,
            updatedAt: provider.updatedAt
        };
    }

    get info() {
        return this.provider;
    }
}
