import React from 'react';
import { withRouter } from 'react-router-dom';
import { HoverableContainer, HoverableLink } from '../..';
import { NavigationLink } from '../style';
import { MenuContainer, MenuContent, CloseBox } from './style';
import { routes } from '../../../../Constants/routes';
import { externalLinks } from '../../../../Constants/externalLinks';
import { ExternalLink } from '../Navbar';
import { Icon } from '../../../Components/Icons';

class BurgerMenu extends React.Component {
    state = {
        isOpen: false
    };

    toggleMenu = () =>
        this.setState(prevState => {
            return {
                isOpen: !prevState.isOpen
            };
        });

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.toggleMenu();
        else if (prevProps.location.key !== this.props.location.key) this.toggleMenu();
    }

    render() {
        return (
            <React.Fragment>
                <HoverableContainer>
                    <NavigationLink onClick={this.toggleMenu}>Menu</NavigationLink>
                </HoverableContainer>
                {this.state.isOpen && (
                    <MenuContainer>
                        <MenuContent>
                            <HoverableLink className="link" to={routes.HOME}>
                                <NavigationLink>Pensions Calculator</NavigationLink>
                            </HoverableLink>
                            <HoverableLink className="link" to={routes.COMPARE}>
                                <NavigationLink>Compare funds</NavigationLink>
                            </HoverableLink>
                            <ExternalLink className="link" path={externalLinks.MEDIUM_BLOG}>
                                <NavigationLink>Blog</NavigationLink>
                            </ExternalLink>
                            <CloseBox onClick={this.toggleMenu}>
                                <Icon.Box size={'12x12'}>
                                    <Icon.Close />
                                </Icon.Box>
                            </CloseBox>
                        </MenuContent>
                    </MenuContainer>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(BurgerMenu);
