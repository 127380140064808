export const splitNumberWithCommas = number => {
    if (typeof +number === 'number') return parseInt(number).toLocaleString();
    return number;
};

export const areObjectsEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);

export const sumArray = arr => arr.reduce((a, b) => parseInt(a) + parseInt(b), 0);

export const removeFirstCharacter = string => string.substring(1);

export const removeLastCharacter = string => string.toString().substring(0, string.length - 1);

export const handleCurrencyInputSelection = e =>
    e.target.setSelectionRange(1, e.target.value.length);

export const handlePercentageInputSelection = e =>
    e.target.setSelectionRange(0, e.target.value.length - 1);

export const toJSON = obj => {
    if (typeof obj === 'object' && obj !== null) return JSON.stringify(obj);
    else throw new Error('Should be an object');
};

export const toFormData = requestData => {
    const formData = new FormData();

    for (let key in requestData) {
        if (Array.isArray(requestData[key])) {
            requestData[key].forEach(phone => formData.append(`${key}[]`, phone));
        } else {
            formData.append(`${key}`, requestData[key]);
        }
    }

    return formData;
};

export const disableBodyScrolling = () => (document.body.style.overflow = 'hidden');
export const enableBodyScrolling = () => (document.body.style.overflow = 'auto');
export const getNumberOfDigitsInNumber = number => number.toString().length;
