import React from 'react';
import { NavigationContainer, NavigationLink } from './style';
import { externalLinks } from '../../../Constants/externalLinks';
import { routes } from '../../../Constants/routes';
import { HoverableLink } from '..';
import { withScreenWidth } from '../../HOCs/withScreenWidth';
import { screenSizes } from '../../../Constants/screenSizes';
import BurgerMenu from './BurgerMenu';

// todo: move screen width to store mb ?
class NavBar extends React.Component {
    state = {
        isMenuOpen: false
    };

    render() {
        return this.props.screenSize === screenSizes.DESKTOP ? (
            <NavigationContainer>
                <HoverableLink className="link" to={routes.HOME}>
                    <NavigationLink>Pensions Calculator</NavigationLink>
                </HoverableLink>
                <HoverableLink className="link" to={routes.COMPARE}>
                    <NavigationLink>Compare funds</NavigationLink>
                </HoverableLink>
                <ExternalLink className="link" path={externalLinks.MEDIUM_BLOG}>
                    <NavigationLink>Blog</NavigationLink>
                </ExternalLink>
            </NavigationContainer>
        ) : (
            <BurgerMenu />
        );
    }
}

export default withScreenWidth(NavBar);

export class ExternalLink extends React.Component {
    handleRedirectClick = () => (window.location.href = this.props.path);
    render() {
        return (
            <div style={{ cursor: 'pointer' }} onClick={this.handleRedirectClick}>
                {this.props.children}
            </div>
        );
    }
}
