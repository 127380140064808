import React from 'react';
import { VerticalCenterContainer, MessageText, ActionText } from './style';
import { Icon } from '../Icons';
import { EmojiFactory } from '../Emoji/index';
import { emoji } from '../../../Constants/emojis';
import { HoverableContainer } from '../../Layouts';

export const EmailSubmittedEmptyState = ({ onAction, forModal, children }) => (
    <VerticalCenterContainer forModal={forModal}>
        {children}
        <Icon.Box size={'115x88'}>
            <Icon.EmailSubmitted />
        </Icon.Box>
        <MessageText>
            Thank you! We will email you with more information soon{' '}
            <EmojiFactory type={emoji.PARTY_POPPER} />{' '}
        </MessageText>
        <HoverableContainer onClick={onAction}>
            <ActionText>Got it!</ActionText>
        </HoverableContainer>
    </VerticalCenterContainer>
);
