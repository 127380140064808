import styled from 'styled-components';

export const IconBox = styled.div`
    position: relative;
    margin: ${props => (props.margin ? props.margin : '0 5px')};
    cursor: ${props => (props.cursor ? props.cursor : null)};

    ${props => {
        const [width, height] = props.size.split('x');

        return {
            height: `${height}px`,
            width: `${width}px`
        };
    }};

    opacity: ${props => {
        if (props.opacity) {
            return props.opacity;
        } else {
            return null;
        }
    }};

    & > svg,
    & > img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
`;
