import React from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Container, ErrorMessageBox, CloseBox } from './style';
import { ErrorActionCreators } from '../../../Store/ActionCreators/ErrorActionCreators';
import { Icon } from '../../Components/Icons';

const ROOT_ELEMENT = document.getElementById('root');

class ErrorMessageContainer extends React.Component {
    render() {
        const { hasError, clearErrors } = this.props;
        return (
            hasError &&
            ReactDOM.createPortal(
                <Container>
                    <ErrorMessageBox>
                        <CloseBox onClick={clearErrors}>
                            <Icon.Box size={'12x12'}>
                                <Icon.Close></Icon.Close>
                            </Icon.Box>
                        </CloseBox>
                        Something went wrong. Try again later
                    </ErrorMessageBox>
                </Container>,
                ROOT_ELEMENT
            )
        );
    }
}

const mapStateToProps = ({ errors }) => ({
    hasError: errors.hasError
});

const mapDispatchToProps = dispatch => ({
    clearErrors: () => dispatch(ErrorActionCreators.clearAllErrors())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorMessageContainer);
