import * as types from '../ActionTypes/CalculatorActionTypes';
import {
    calculatorFormulaInitialValues as initialValues,
    calculatorLimits
} from '../../Constants/calculator';
import {
    handleCurrencyInput,
    handlePercentageInput
} from '../../BusinessLogic/Handlers/Calculator';
import { getNumberOfDigitsInNumber } from '../../BusinessLogic/Handlers/Common';

const initialState = {
    // general
    sex: initialValues.SEX,
    age: initialValues.AGE_TODAY,
    retirementAge: initialValues.PLANNED_RETIREMENT_AGE,
    salary: initialValues.SALARY,
    contribution: initialValues.TOTAL_CONTRIBUTION,
    currentPensionPot: initialValues.CURRENT_PENSION_POT,
    desiredPension: initialValues.DESIRED_PENSION_AT_RETIREMENT,

    // advanced
    taxFreeCashAllowance: initialValues.TAX_FREE_ALLOWANCE,
    inflationRate: initialValues.INFLATION_RATE,
    annualSalaryIncreaseRate: initialValues.ANNUAL_SALARY_INCREASE_RATE,
    includeStatePension: initialValues.INCLUDE_STATE_PENSION,
    preRetirementInvestmentReturnRate: initialValues.PRE_RETIREMENT_INVESTMENT_RETURN,
    postRetirementInvestmentReturnRate: initialValues.POST_RETIREMENT_INVESTMENT_RETURN
};

export const CalculatorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // general
        case types.SET_SEX: {
            return {
                ...state,
                sex: payload
            };
        }

        case types.SET_AGE: {
            return {
                ...state,
                age: payload
            };
        }

        case types.SET_RETIREMENT_AGE: {
            if (payload < state.age) return state;
            return {
                ...state,
                retirementAge: payload
            };
        }

        case types.SET_SALARY: {
            const { isValid, output } = handleCurrencyInput(
                payload,
                getNumberOfDigitsInNumber(state.salary)
            );
            if (!isValid) return state;

            switch (true) {
                case output > calculatorLimits.MAX_ALLOWED_SALARY:
                case output <= 0:
                    return state;
                default:
                    return {
                        ...state,
                        salary: output
                    };
            }
        }

        case types.SET_CONTRIBUTION: {
            const { isValid, output } = handlePercentageInput(payload);
            if (!isValid) return state;

            switch (true) {
                case output < calculatorLimits.MIN_ALLOWED_CONTRIBUTION:
                case output > calculatorLimits.MAX_ALLOWED_CONTRIBUTION:
                    return state;
                default:
                    return { ...state, contribution: output };
            }
        }

        case types.SET_CURRENT_PENSION_POT: {
            const { isValid, output } = handleCurrencyInput(payload);
            if (!isValid) return state;

            switch (true) {
                case payload < 0:
                    return state;
                default: {
                    return {
                        ...state,
                        currentPensionPot: output
                    };
                }
            }
        }

        case types.SET_DESIRED_PENSION: {
            const { isValid, output } = handlePercentageInput(payload);
            if (!isValid) return state;

            switch (true) {
                case output < calculatorLimits.MIN_ALLOWED_ANNUAL_PENSION:
                case output > calculatorLimits.MAX_ALLOWED_ANNUAL_PENSION:
                    return state;
                default:
                    return { ...state, desiredPension: output };
            }
        }

        // advanced

        case types.SET_TAX_FREE_CASH_ALLOWANCE: {
            return {
                ...state,
                taxFreeCashAllowance: payload
            };
        }

        case types.SET_INFLATION_RATE: {
            const { isValid, output } = handlePercentageInput(payload);
            if (!isValid) return state;

            switch (true) {
                case output < calculatorLimits.MIN_ALLOWED_INFLATION:
                case output > calculatorLimits.MAX_ALLOWED_INFLATION:
                    return state;
                default:
                    return {
                        ...state,
                        inflationRate: output
                    };
            }
        }

        case types.SET_ANNUAL_SALARY_INCREASE_RATE: {
            const { isValid, output } = handlePercentageInput(payload);
            if (!isValid) return state;

            switch (true) {
                case output < calculatorLimits.MIN_ALLOWED_SALARY_INCREASE_RATE:
                case output > calculatorLimits.MAX_ALLOWED_SALARY_INCREASE_RATE:
                    return state;
                default:
                    return {
                        ...state,
                        annualSalaryIncreaseRate: output
                    };
            }
        }

        case types.SET_INCLUDE_STATE_PENSION: {
            return {
                ...state,
                includeStatePension: payload
            };
        }

        case types.SET_PRE_RETIREMENT_INVESTMENT_RETURN_RATE: {
            const { isValid, output } = handlePercentageInput(payload);
            if (!isValid) return state;

            switch (true) {
                case output < calculatorLimits.MIN_ALLOWED_PRE_RETIREMENT_INVESTMENT_RETURN_RATE:
                case output > calculatorLimits.MAX_ALLOWED_PRE_RETIREMENT_INVESTMENT_RETURN_RATE:
                    return state;
                default:
                    return {
                        ...state,
                        preRetirementInvestmentReturnRate: output
                    };
            }
        }

        case types.SET_POST_RETIREMENT_INVESTMENT_RETURN_RATE: {
            const { isValid, output } = handlePercentageInput(payload);
            if (!isValid) return state;

            switch (true) {
                case output < calculatorLimits.MIN_ALLOWED_POST_RETIREMENT_INVESTMENT_RETURN_RATE:
                case output > calculatorLimits.MAX_ALLOWED_POST_RETIREMENT_INVESTMENT_RETURN_RATE:
                    return state;
                default:
                    return {
                        ...state,
                        postRetirementInvestmentReturnRate: output
                    };
            }
        }

        default: {
            return state;
        }
    }
};
