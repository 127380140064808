import React from 'react';
import { HeaderContainer } from './style';
import { Logo } from './Logo';
import NavBar from './Navbar';

export const Header = () => (
    <HeaderContainer>
        <Logo />
        <NavBar />
    </HeaderContainer>
);
