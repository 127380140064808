import React from 'react';
import PropTypes from 'prop-types';

import { ActiveTrack, ValueDisplay } from './style';
import './styletest.css';
import { calculateUIOffsetForProgressBarTrack } from '../../../../BusinessLogic/Handlers/Calculator';

// TODO: Make responsive in the future
const PROGGRESS_BAR_WIDTH = 243;

export class InputRange extends React.Component {
    onValueChange = event => {
        if (+event.target.value < this.props.minAllowed) {
            this.inputRef.value = this.props.minAllowed;
            this.props.onChange(this.props.minAllowed);
        } else if (+event.target.value > this.props.maxAllowed) {
            this.inputRef.value = this.props.maxAllowed;
            this.props.onChange(this.props.maxAllowed);
        } else this.props.onChange(+event.target.value);
    };

    calculateActiveTrackWidth = () => {
        return calculateUIOffsetForProgressBarTrack(this.props.value);
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.minAllowed !== this.props.minAllowed &&
            this.props.value < this.props.minAllowed
        ) {
            this.props.onChange(this.props.minAllowed);
            this.inputRef.value = this.props.minAllowed;
        }
    }

    calculateOutputLabelPosition = () => {
        const { value, max } = this.props;
        const trackPercentage = value / max;
        const labelPosition = PROGGRESS_BAR_WIDTH * trackPercentage;
        const offset = (labelPosition - 10) / 5 / 2;

        if (value >= 0 && value < 10) return { xPos: labelPosition };
        else if (value >= 10 && value < 100) return { xPos: labelPosition - offset };
        else return { xPos: labelPosition - 35 };
    };

    render() {
        const { min, max, step, value } = this.props;

        return (
            <div className="input-range">
                <input
                    type="range"
                    ref={node => (this.inputRef = node)}
                    onChange={this.onValueChange}
                    className="rangeslider"
                    min={min}
                    max={max}
                    step={step}
                    defaultValue={value}
                />
                <ActiveTrack {...this.calculateActiveTrackWidth()} />
                <ValueDisplay {...this.calculateOutputLabelPosition()}>
                    {this.props.value}
                </ValueDisplay>
            </div>
        );
    }
}

InputRange.defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    value: 30
};

InputRange.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};
