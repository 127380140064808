import { getListOfProviders } from './requestConfigs';

class ProvidersApi {
    getListOfProviders = getListOfProviders;
}

class ProvidersFactory {
    providers = ProvidersApi;
    create() {
        return new this.providers();
    }
}

export const providers = new ProvidersFactory();
