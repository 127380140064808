import React from 'react';
import { Link } from 'react-router-dom';
import { FooterContainer, FooterContent, FooterLogo } from './style';
import { FooterLinks } from './FooterLinks';
import { routes } from '../../../Constants/routes';

export const Footer = () => (
    <FooterContainer>
        <FooterContent>
            <Link to={routes.HOME}>
                <FooterLogo />
            </Link>

            <FooterLinks />
        </FooterContent>
    </FooterContainer>
);
