import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { HeadingText } from '../../Layouts';
import { LargeHeader, HeaderContent, HeaderOverlay } from './style';

import { Subheader } from './Subheader';

import Calculator from './Calculator';
import { calculateResult } from '../../../BusinessLogic/Handlers/Calculator';
import SubmitEmailBlock from '../../Components/SubmitEmailBlock';

const PensionsCalculator = props => {
    const [areAdvancedQuestionsShown, showAdvancedQuestions] = useState(false);

    return (
        <React.Fragment>
            <LargeHeader>
                <HeaderOverlay />
                <HeaderContent>
                    <HeadingText alignCenter={true}>Pension Calculator</HeadingText>
                    <Calculator
                        areAdvancedQuestionsShown={areAdvancedQuestionsShown}
                        showAdvancedQuestions={showAdvancedQuestions}
                    />
                </HeaderContent>
            </LargeHeader>

            <Subheader {...props.result} />

            <SubmitEmailBlock />
        </React.Fragment>
    );
};

const mapStateToProps = ({ calculator }) => ({
    result: calculateResult(calculator)
});

PensionsCalculator.defaultProps = {
    result: {}
};

PensionsCalculator.propTypes = {
    result: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(PensionsCalculator);
