import styled from 'styled-components';
import { gradients, colorCodes } from '../../../Constants/colors';
import { Text, HoverableLink } from '../../Layouts';
import MountainsBackground from '../../../Assets/Images/mountains-bg@3x.png';

// large header
export const LargeHeader = styled.div`
    width: 100%;
    min-height: 444px;
    background: ${gradients.PRIMARY};
    position: relative;
    padding: 66px 0;
    display: flex;
    justify-content: center;
`;

export const HeaderOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 183px;
    bottom: 0;
    left: 0;
    background-image: url(${MountainsBackground});
    background-size: cover;
`;

export const HeaderContent = styled.div`
    z-index: 2;
`;

// subheader
export const SubheaderContainer = styled.div`
    width: 100%;
    min-height: 138px;
    background-color: ${colorCodes.GREY7};

    padding: 50px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SubheaderTitle = styled(Text)`
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 18px;
    color: ${colorCodes.GREY4};
    opacity: 0.3;
    text-transform: uppercase;
`;

export const SubheaderDisclaimer = styled(Text)`
    text-align: center;
    font-size: 18px;
    color: ${colorCodes.GREY8};
    margin-bottom: 16px;
`;

export const DisclaimerLink = styled(HoverableLink)`
    color: ${colorCodes.SECONDARY};
    font-weight: bold;
    margin-left: 5px;
`;

export const BottomSubheaderContainer = styled(SubheaderContainer)`
    background-color: ${colorCodes.WHITE};
`;

export const SubheaderTextContainer = styled.div`
    min-width: 340px;
    max-width: 1000px;
    line-height: 1.6;
    color: ${colorCodes.PRIMARY};
    font-size: 30px;
    font-weight: bold;
    text-align: center;
`;

export const ImportantText = styled.span`
    color: ${colorCodes.SECONDARY};
    border-bottom: 2px solid ${colorCodes.SECONDARY};
`;
