import React from 'react';

export class NumbersToggler extends React.Component {
    handleIncrease = () => this.props.onChange(+this.props.value + this.props.step);
    handleDecrease = () => this.props.onChange(+this.props.value - this.props.step);

    render() {
        return this.props.renderComponent({
            onIncrease: this.handleIncrease,
            onDecrease: this.handleDecrease,
            handleChange: this.props.onChange,
            onFocus: this.props.onFocus,
            decorate: this.props.decorate,
            value: this.props.value,
            mask: this.props.mask,
            percent: this.props.percent,
            currency: this.props.currency
        });
    }
}
