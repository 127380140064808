import React from 'react';
import { screenSizes } from '../../Constants/screenSizes';

export const withScreenWidth = WrappedComponent => {
    return class extends React.Component {
        state = {
            screen: null
        };

        detectScreenSize = () => {
            const screenWidth = window.innerWidth;

            switch (true) {
                case screenWidth <= 767:
                    return screenSizes.MOBILE;
                case screenWidth <= 1025:
                    return screenSizes.TABLET;
                default:
                    return screenSizes.DESKTOP;
            }
        };

        handleWindowResize = () => {
            const { screen } = this.state;
            let screenSize = this.detectScreenSize();

            if (screenSize !== screen) this.setState({ screen: screenSize });
        };

        componentDidMount() {
            const screen = this.detectScreenSize();
            this.setState({ screen });

            window.addEventListener('resize', this.handleWindowResize);
        }

        componentWillUnmount = () => {
            window.removeEventListener('resize', this.handleWindowResize);
        };

        render() {
            const { screen } = this.state;

            return <WrappedComponent {...this.props} screenSize={screen} />;
        }
    };
};
