import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';

export const PageContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const TextContainer = styled.div`
    padding: 40px 25px 60px 25px;
    min-width: 350px;
    max-width: 600px;
    color: ${colorCodes.GREY6};
    text-align: justify;
    font-size: 16px;

    & a {
        color: ${colorCodes.TERMS_LINK};
    }
`;

export const Head = styled.h2``;
