import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { routes } from '../Constants/routes';
import { Terms } from './Containers/Terms';
import { PrivacyPolicy } from './Containers/PrivacyPolicy';
import PensionsCalculator from './Containers/PensionsCalculator';
import { NotFoundPage } from './Containers/NotFoundPage';
import { Header } from './Layouts/Header';
import { Footer } from './Layouts/Footer';
import { Disclaimer } from './Containers/Disclaimer';
import ErrorMessageContainer from './Containers/ErrorMessage';
import CompareFundsPage from './Containers/CompareFunds';

const App = () => (
    <Router>
        <Header />
        <ErrorMessageContainer />
        <Switch>
            <Route exact path={routes.HOME} component={PensionsCalculator} />
            <Route path={routes.TERMS_OF_SERVICE} component={Terms} />
            <Route path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route path={routes.DISCLAIMER} component={Disclaimer} />
            <Route path={routes.COMPARE} component={CompareFundsPage} />
            <Route path={'*'} component={NotFoundPage} />
        </Switch>
        <Footer />
    </Router>
);

export default App;
