import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { InputRange } from '../ProgressBar';

import { CalculatorActionCreators } from '../../../../Store/ActionCreators/CalculatorActionCreators';
import {
    Container,
    ShowMoreLabel,
    AdvancedQuestionsContainer,
    RadioItem,
    RadioButton,
    RadioLabel,
    RadioText,
    RadioButtonsWrapper
} from './style';
import { NumbersToggler } from '../../NumbersToggler';
import { DefaultToggler } from '../../../Components/DefaultToggler';
import { LabeledInput } from './LabeledInput';

const Calculator = props => (
    <Container>
        <LabeledInput label={'Your sex:'}>
            <RadioButtonsWrapper>
                <RadioItem>
                    <RadioButton
                        type={'radio'}
                        onChange={() => props.setSex(0)}
                        checked={props.sex === 0}
                    />
                    <RadioLabel />
                    <RadioText>Male</RadioText>
                </RadioItem>
                <RadioItem>
                    <RadioButton
                        type={'radio'}
                        onChange={() => props.setSex(1)}
                        checked={props.sex === 1}
                    />
                    <RadioLabel />
                    <RadioText>Female</RadioText>
                </RadioItem>
            </RadioButtonsWrapper>
        </LabeledInput>
        <br />

        <LabeledInput label={'Your age today:'}>
            <InputRange minAllowed={18} maxAllowed={75} onChange={props.setAge} value={props.age} />
        </LabeledInput>
        <br />

        <LabeledInput label={'Planned retirement age:'}>
            <InputRange
                maxAllowed={75}
                minAllowed={props.age < 55 ? 55 : props.age}
                onChange={props.setRetirementAge}
                value={props.retirementAge}
            />
        </LabeledInput>
        <br />

        <LabeledInput label={'Your salary:'}>
            <NumbersToggler
                mask={'£9999999999'}
                currency={true}
                step={1000}
                onChange={props.setSalary}
                value={props.salary}
                renderComponent={props => <DefaultToggler {...props} />}
            />
        </LabeledInput>
        <br />

        <LabeledInput label={'Total contribution %:'}>
            <NumbersToggler
                step={1}
                onChange={props.setContribution}
                value={props.contribution}
                renderComponent={props => <DefaultToggler {...props} />}
            />
        </LabeledInput>
        <br />

        <LabeledInput label={'Current pension pot:'}>
            <NumbersToggler
                currency={true}
                step={1000}
                mask={'£9999999999'}
                onChange={props.setCurrentPensionPot}
                value={props.currentPensionPot}
                renderComponent={props => <DefaultToggler {...props} />}
            />
        </LabeledInput>
        <br />

        <LabeledInput label={'Desired pension at retirement:'} sublabel={'As % of final salary'}>
            <NumbersToggler
                step={1}
                percent={true}
                onChange={props.setDesiredPension}
                value={props.desiredPension}
                renderComponent={props => <DefaultToggler {...props} />}
            />
        </LabeledInput>
        <br />

        <ShowMoreLabel
            onClick={
                props.areAdvancedQuestionsShown
                    ? () => props.showAdvancedQuestions(false)
                    : () => props.showAdvancedQuestions(true)
            }
        >
            {props.areAdvancedQuestionsShown ? 'Hide advanced settings' : 'Show advanced settings'}
        </ShowMoreLabel>

        {props.areAdvancedQuestionsShown && (
            <AdvancedQuestionsContainer>
                <LabeledInput label={'Do you want to take Tax free cash allowance at retirement?'}>
                    <RadioButtonsWrapper>
                        <RadioItem>
                            <RadioButton
                                type={'radio'}
                                onChange={() => props.setTaxFreeCashAllowance(true)}
                                checked={props.taxFreeCashAllowance}
                            />
                            <RadioLabel />
                            <RadioText>Yes</RadioText>
                        </RadioItem>
                        <RadioItem>
                            <RadioButton
                                type={'radio'}
                                onChange={() => props.setTaxFreeCashAllowance(false)}
                                checked={!props.taxFreeCashAllowance}
                            />
                            <RadioLabel />
                            <RadioText>No</RadioText>
                        </RadioItem>
                    </RadioButtonsWrapper>
                </LabeledInput>
                <br />

                <LabeledInput label={'Inflation:'}>
                    <NumbersToggler
                        step={1}
                        percent={true}
                        onChange={props.setInflationRate}
                        value={props.inflationRate}
                        renderComponent={props => <DefaultToggler {...props} />}
                    />
                </LabeledInput>
                <br />

                <LabeledInput label={'Annual salary increases:'} sublabel={'Above inflation'}>
                    <NumbersToggler
                        step={1}
                        percent={true}
                        onChange={props.setAnnualSalaryIncreaseRate}
                        value={props.annualSalaryIncreaseRate}
                        renderComponent={props => <DefaultToggler {...props} />}
                    />
                </LabeledInput>
                <br />

                <LabeledInput label={'Include state pension:'}>
                    <RadioButtonsWrapper>
                        <RadioItem>
                            <RadioButton
                                type={'radio'}
                                onChange={() => props.setIncludeStatePension(true)}
                                checked={props.includeStatePension}
                            />
                            <RadioLabel />
                            <RadioText>Yes</RadioText>
                        </RadioItem>
                        <RadioItem>
                            <RadioButton
                                type={'radio'}
                                onChange={() => props.setIncludeStatePension(false)}
                                checked={!props.includeStatePension}
                            />
                            <RadioLabel />
                            <RadioText>No</RadioText>
                        </RadioItem>
                    </RadioButtonsWrapper>
                </LabeledInput>
                <br />

                <LabeledInput label={'Investment return:'} sublabel={'(Pre-retirement)'}>
                    <NumbersToggler
                        step={1}
                        percent={true}
                        onChange={props.setPreRetirementInvestmentReturnRate}
                        value={props.preRetirementInvestmentReturnRate}
                        renderComponent={props => <DefaultToggler {...props} />}
                    />
                </LabeledInput>
                <br />

                <LabeledInput label={'Investment return:'} sublabel={'(Post-retirement)'}>
                    <NumbersToggler
                        step={1}
                        percent={true}
                        onChange={props.setPostRetirementInvestmentReturnRate}
                        value={props.postRetirementInvestmentReturnRate}
                        renderComponent={props => <DefaultToggler {...props} />}
                    />
                </LabeledInput>
                <br />
            </AdvancedQuestionsContainer>
        )}
    </Container>
);

const mapStateToProps = ({ calculator }) => ({
    // general
    sex: calculator.sex,
    age: calculator.age,
    retirementAge: calculator.retirementAge,
    salary: calculator.salary,
    contribution: calculator.contribution,
    currentPensionPot: calculator.currentPensionPot,
    desiredPension: calculator.desiredPension,

    // advanced
    taxFreeCashAllowance: calculator.taxFreeCashAllowance,
    inflationRate: calculator.inflationRate,
    annualSalaryIncreaseRate: calculator.annualSalaryIncreaseRate,
    includeStatePension: calculator.includeStatePension,
    preRetirementInvestmentReturnRate: calculator.preRetirementInvestmentReturnRate,
    postRetirementInvestmentReturnRate: calculator.postRetirementInvestmentReturnRate
});

const mapDispatchToProps = dispatch => ({
    // general
    setSex: sex => dispatch(CalculatorActionCreators.setSex(sex)),
    setAge: age => dispatch(CalculatorActionCreators.setAge(age)),
    setRetirementAge: retirementAge =>
        dispatch(CalculatorActionCreators.setRetirementAge(retirementAge)),
    setSalary: salary => dispatch(CalculatorActionCreators.setSalary(salary)),
    setContribution: contribution =>
        dispatch(CalculatorActionCreators.setContribution(contribution)),
    setCurrentPensionPot: currentPensionPot =>
        dispatch(CalculatorActionCreators.setCurrentPensionPot(currentPensionPot)),
    setDesiredPension: desiredPension =>
        dispatch(CalculatorActionCreators.setDesiredPension(desiredPension)),

    // advanced
    setTaxFreeCashAllowance: cashAllowance =>
        dispatch(CalculatorActionCreators.setTaxFreeCashAllowance(cashAllowance)),
    setInflationRate: inflationRate =>
        dispatch(CalculatorActionCreators.setInflationRate(inflationRate)),
    setAnnualSalaryIncreaseRate: increaseRate =>
        dispatch(CalculatorActionCreators.setAnnualSalaryIncreaseRate(increaseRate)),
    setIncludeStatePension: includeStatePension =>
        dispatch(CalculatorActionCreators.setIncludeStatePension(includeStatePension)),
    setPreRetirementInvestmentReturnRate: returnRate =>
        dispatch(CalculatorActionCreators.setPreRetirementInvestmentReturnRate(returnRate)),
    setPostRetirementInvestmentReturnRate: returnRate =>
        dispatch(CalculatorActionCreators.setPostRetirementInvestmentReturnRate(returnRate))
});

Calculator.propTypes = {
    // calculator general
    sex: PropTypes.number.isRequired,
    age: PropTypes.number.isRequired,
    retirementAge: PropTypes.number.isRequired,
    salary: PropTypes.number.isRequired,
    contribution: PropTypes.number.isRequired,
    currentPensionPot: PropTypes.number.isRequired,
    desiredPension: PropTypes.number.isRequired,
    setSex: PropTypes.func.isRequired,
    setAge: PropTypes.func.isRequired,
    setRetirementAge: PropTypes.func.isRequired,
    setSalary: PropTypes.func.isRequired,
    setContribution: PropTypes.func.isRequired,
    setCurrentPensionPot: PropTypes.func.isRequired,
    setDesiredPension: PropTypes.func.isRequired,

    // calculator advanced
    taxFreeCashAllowance: PropTypes.bool.isRequired,
    inflationRate: PropTypes.number.isRequired,
    annualSalaryIncreaseRate: PropTypes.number.isRequired,
    includeStatePension: PropTypes.bool.isRequired,
    preRetirementInvestmentReturnRate: PropTypes.number.isRequired,
    postRetirementInvestmentReturnRate: PropTypes.number.isRequired,
    setTaxFreeCashAllowance: PropTypes.func.isRequired,
    setInflationRate: PropTypes.func.isRequired,
    setAnnualSalaryIncreaseRate: PropTypes.func.isRequired,
    setIncludeStatePension: PropTypes.func.isRequired,
    setPreRetirementInvestmentReturnRate: PropTypes.func.isRequired,
    setPostRetirementInvestmentReturnRate: PropTypes.func.isRequired,

    // other props
    showAdvancedQuestions: PropTypes.func.isRequired,
    areAdvancedQuestionsShown: PropTypes.bool.isRequired
};

Calculator.defaultProps = {
    // calculator general
    sex: 0,
    age: 30,
    retirementAge: 68,
    salary: 27000,
    contribution: 8,
    currentPensionPot: 12000,
    desiredPension: 66,
    setSex: () => {},
    setAge: () => {},
    setRetirementAge: () => {},
    setSalary: () => {},
    setContribution: () => {},
    setCurrentPensionPot: () => {},
    setDesiredPension: () => {},

    // calculator advanced
    taxFreeCashAllowance: true,
    inflationRate: 3,
    annualSalaryIncreaseRate: 1,
    includeStatePension: true,
    preRetirementInvestmentReturnRate: 7,
    postRetirementInvestmentReturnRate: 5,
    setTaxFreeCashAllowance: () => {},
    setInflationRate: () => {},
    setAnnualSalaryIncreaseRate: () => {},
    setIncludeStatePension: () => {},
    setPreRetirementInvestmentReturnRate: () => {},
    setPostRetirementInvestmentReturnRate: () => {},

    // other props
    showAdvancedQuestions: () => {},
    areAdvancedQuestionsShown: false
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Calculator);
