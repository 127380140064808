import React from 'react';
import ReactDOM from 'react-dom';
import { Container } from './style';

const ROOT_ELEMENT = document.getElementById('root');

export class ModalContainer extends React.Component {
    render() {
        return ReactDOM.createPortal(<Container>{this.props.children}</Container>, ROOT_ELEMENT);
    }
}
