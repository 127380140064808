import styled from 'styled-components';
import { gradients } from '../../../../Constants/colors';

export const MenuContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: ${gradients.PRIMARY};
`;

export const MenuContent = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 32px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    & .link {
        margin-bottom: 20px;
    }
`;

export const CloseBox = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    color: ${props => (props.color ? props.color : 'white')};

    cursor: pointer;
    transition: all 0.25s;

    &:hover {
        opacity: 0.75;
    }
`;
