import React from 'react';
import { InputRow, LabelWrapper, LabelText, SublabelText } from './style';

export const LabeledInput = ({ label, sublabel, children }) => (
    <InputRow>
        <LabelWrapper>
            <LabelText>
                {label}
                {sublabel && (
                    <React.Fragment>
                        <br />
                        <SublabelText>{sublabel}</SublabelText>
                    </React.Fragment>
                )}
            </LabelText>
        </LabelWrapper>
        {children}
    </InputRow>
);
