import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';

export const TogglerRow = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;

    & .item:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    & .item:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        margin-right: 0;
    }
`;

export const Item = styled.div`
    padding: 6px 28px;
    font-size: 11px;
    text-align: center;
    margin-right: 2px;
    cursor: pointer;
    color: ${colorCodes.WHITE};
    ${props => {
        if (props.isActive) return `background-color: rgba(239, 240, 242, 0.5)`;
        return `background-color: rgba(239, 240, 242, 0.18)`;
    }};

    &:hover {
        background-color: rgba(239, 240, 242, 0.5);
    }

    transition: all 0.25s;
    text-transform: uppercase;
`;
