import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';
import { Button } from '../../Layouts';

export const SalaryContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ActionButton = styled(Button)`
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    background-color: rgba(239, 240, 242, 0.18);
    border-radius: 50%;
    color: ${colorCodes.WHITE};
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: center;
    aling-items: center;
`;

export const SalaryInput = styled.input`
    width: 185px;
    height: 45px;
    border-radius: 3px;
    background-color: rgba(239, 240, 242, 0.18);
    margin: 0 10px;
    color: ${colorCodes.WHITE};
    font-size: 30px;
    text-align: center;
    border: none;
    outline: none;
    font-weight: bold;

    @media (max-width: 630px) {
        width: 155px;
        font-size: 26px;
    }
`;

export const ContributionInput = styled(SalaryInput)`
    width: 100px;
`;
