class Path {
    get host() {
        return 'https://api.pensionspenguin.com';
    }

    get url() {
        return `${this.host}/api`;
    }

    submitEmail() {
        return 'https://us-central1-pensions-penguin-389ed.cloudfunctions.net/addSubscriber';
    }

    getListOfProviders() {
        return `${this.url}/providers`;
    }
}

class PathFactory {
    path = Path;
    create() {
        return new this.path();
    }
}

export const path = new PathFactory();
