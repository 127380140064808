import * as types from '../ActionTypes/AuthActionTypes';

const initialState = {
    statuses: {
        isEmailSubmitting: false,
        isEmailSent: false
    }
};

export const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SUBMIT_EMAIL_REQUEST: {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    isEmailSubmitting: true
                }
            };
        }

        case types.SUBMIT_EMAIL_SUCCESS: {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    isEmailSubmitting: false,
                    isEmailSent: true
                }
            };
        }

        case types.SUBMIT_EMAIL_FAILED: {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    isEmailSubmitting: false
                }
            };
        }

        case types.TYPE_EMAIL_AGAIN: {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    isEmailSent: false
                }
            };
        }

        default:
            return state;
    }
};
