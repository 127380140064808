import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';
import PenguinLogo from '../../../Assets/Images/penguin-logo-no-text@3x.png';
import { Text } from '..';

export const FooterContainer = styled.div`
    width: 100%;
    height: 62px;
    background-color: ${colorCodes.BLUE1};
    padding: 0 24px;
    display: flex;
    align-items: center;
`;

export const FooterContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// logo
export const FooterLogo = styled.div`
    width: 22px;
    height: 27px;
    background-image: url(${PenguinLogo});
    background-size: cover;
`;

// links
export const LinksContainer = styled.div`
    display: flex;
    align-items: center;

    & > a {
        margin-right: 30px;
    }
`;

export const LinkText = styled(Text)`
    color: ${colorCodes.GREY3};
    font-size: 10px;
`;

export const SocialLinks = styled.div`
    display: flex;

    & > a {
        margin-left: 5px;
    }
`;
