import * as types from '../ActionTypes/ProvidersActionTypes';

export class ProvidersActionCreators {
    static changeSortRange = payload => ({
        type: types.CHANGE_SORT_RANGE,
        payload
    });

    static getListOfProvidersRequest = () => ({
        type: types.GET_LIST_OF_PROVIDERS_REQUEST
    });

    static getListOfProvidersSuccess = payload => ({
        type: types.GET_LIST_OF_PROVIDERS_SUCCESS,
        payload
    });

    static getListOfProvidersFailed = payload => ({
        type: types.GET_LIST_OF_PROVIDERS_FAILED,
        payload
    });
}
