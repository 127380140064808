import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(33, 44, 74, 0.8);
    z-index: 111;
    display: flex;
    justify-content: center;
    align-items: center;
`;
