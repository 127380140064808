export const colorCodes = {
    WHITE: '#fff',
    BLACK: '#000',
    PRIMARY: '#50329c',
    SECONDARY: '#ff31a4',
    GREY1: '#d8def2',
    GREY2: '#f0f3f9',
    GREY3: '#8b92a2',
    GREY4: '#232b47',
    GREY5: '#f0f3f9',
    GREY6: '#687581',
    GREY7: '#f6f8fb',
    GREY8: '#8d92a1',
    GREY9: '#eaeaea',
    GREY10: '#a5acb4',
    GREY11: '#f6f6fb',
    GREY12: '#5d6a76',
    GREY13: '#838dac',
    BLUE1: '#1e2645',
    BLUE2: '#5c5274',
    TERMS_LINK: '#5f89b1'
};

export const gradients = {
    PRIMARY: 'linear-gradient(to bottom, #3160d2, #542b94)'
};
