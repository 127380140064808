import React from 'react';

export const InputElement = ({
    labelComponent,
    inputComponent,
    errorComponent,
    label,
    errorMessage,
    decorate,
    ...props
}) => {
    const LabelComponent = labelComponent;
    const InputComponent = inputComponent;
    const ErrorComponent = errorComponent;

    return (
        <React.Fragment>
            {label && <LabelComponent>{label}</LabelComponent>}
            <InputComponent {...props} />
            {errorMessage && <ErrorComponent>{errorMessage}</ErrorComponent>}
        </React.Fragment>
    );
};
