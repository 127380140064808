import * as types from '../ActionTypes/CalculatorActionTypes';

export class CalculatorActionCreators {
    // general
    // 0 - male, 1 - female
    static setSex = sex => ({
        type: types.SET_SEX,
        payload: sex
    });

    static setAge = age => ({
        type: types.SET_AGE,
        payload: age
    });

    static setRetirementAge = retirementAge => ({
        type: types.SET_RETIREMENT_AGE,
        payload: retirementAge
    });

    static setSalary = salary => ({
        type: types.SET_SALARY,
        payload: salary
    });

    static setContribution = contribution => ({
        type: types.SET_CONTRIBUTION,
        payload: contribution
    });

    static setCurrentPensionPot = currentPensionPot => ({
        type: types.SET_CURRENT_PENSION_POT,
        payload: currentPensionPot
    });

    static setDesiredPension = desiredPension => ({
        type: types.SET_DESIRED_PENSION,
        payload: desiredPension
    });

    // advanced
    static setTaxFreeCashAllowance = taxFreeCashAllowance => ({
        type: types.SET_TAX_FREE_CASH_ALLOWANCE,
        payload: taxFreeCashAllowance
    });

    static setInflationRate = inflationRate => ({
        type: types.SET_INFLATION_RATE,
        payload: inflationRate
    });

    static setAnnualSalaryIncreaseRate = increaseRate => ({
        type: types.SET_ANNUAL_SALARY_INCREASE_RATE,
        payload: increaseRate
    });

    static setIncludeStatePension = includeStatePension => ({
        type: types.SET_INCLUDE_STATE_PENSION,
        payload: includeStatePension
    });

    static setPreRetirementInvestmentReturnRate = investmentReturnRate => ({
        type: types.SET_PRE_RETIREMENT_INVESTMENT_RETURN_RATE,
        payload: investmentReturnRate
    });

    static setPostRetirementInvestmentReturnRate = investmentReturnRate => ({
        type: types.SET_POST_RETIREMENT_INVESTMENT_RETURN_RATE,
        payload: investmentReturnRate
    });
}
