import * as types from '../ActionTypes/ProvidersActionTypes';
import { ProviderModel } from '../../BusinessLogic/Models/Providers';

const initialState = {
    statuses: {
        providersListLoading: false
    },
    providersList: [],
    sortRange: null
};

export const ProvidersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.CHANGE_SORT_RANGE: {
            let sortedArray = [...state.providersList];

            switch (payload) {
                case 0:
                    sortedArray.sort((a, b) => b.oneYearPerformance - a.oneYearPerformance);
                    break;
                case 1:
                    sortedArray.sort((a, b) => b.threeYearsPerformance - a.threeYearsPerformance);
                    break;
                case 2:
                    sortedArray.sort((a, b) => b.fiveYearsPerformance - a.fiveYearsPerformance);
                    break;
                default:
                    break;
            }

            return {
                ...state,
                sortRange: payload,
                providersList: [...sortedArray]
            };
        }

        case types.GET_LIST_OF_PROVIDERS_REQUEST: {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    providersListLoading: true
                }
            };
        }

        case types.GET_LIST_OF_PROVIDERS_SUCCESS: {
            const providers = mapListOfProviders(payload);

            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    providersListLoading: false
                },
                providersList: [...providers]
            };
        }

        case types.GET_LIST_OF_PROVIDERS_FAILED: {
            return {
                ...state,
                statuses: {
                    ...state.statuses,
                    providersListLoading: false
                }
            };
        }

        default:
            return state;
    }
};

// TODO: move to the model
const mapListOfProviders = providers =>
    providers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(provider => {
            const providerModel = new ProviderModel(provider);
            return { ...providerModel.info };
        });
