import React from 'react';
import {
    RowContainer,
    FundName,
    FundAvatar,
    SelectedText,
    RegularText,
    ViewButton,
    NameAvatarCell,
    PerformanceCell,
    DateCell,
    FundSizeCell,
    SeparatePerformanceCell,
    MobileRowContainter,
    MobileMiddleRow,
    MobileInvestmentsRow,
    PercentageRow,
    GreyText,
    BottomMobileRow,
    BottomMobileItem
} from './style';
import { withScreenWidth } from '../../HOCs/withScreenWidth';
import { screenSizes } from '../../../Constants/screenSizes';
import { DateUtils } from '../../../BusinessLogic/Handlers/Date';

class FundRow extends React.PureComponent {
    render() {
        const { props } = this; 
        
        return props.screenSize !== screenSizes.MOBILE ? (
            <RowContainer>
                <NameAvatarCell>
                    <FundAvatar src={props.logo}></FundAvatar>
                    <FundName>
                        {props.name} {props.fundName && `, ${props.fundName}`}
                    </FundName>
                </NameAvatarCell>
    
                <PerformanceCell>
                    <SeparatePerformanceCell isActive={props.activeTabId === 0}>
                        {props.oneYearPerformance ? `${props.oneYearPerformance}%` : '-'}
                    </SeparatePerformanceCell>
    
                    <SeparatePerformanceCell isActive={props.activeTabId === 1}>
                        {props.threeYearsPerformance ? `${props.threeYearsPerformance}%` : '-'}
                    </SeparatePerformanceCell>
    
                    <SeparatePerformanceCell isActive={props.activeTabId === 2}>
                        {props.fiveYearsPerformance ? `${props.fiveYearsPerformance}%` : '-'}
                    </SeparatePerformanceCell>
                </PerformanceCell>
    
                <DateCell>{props.datePerformance ? DateUtils.formatDate(props.datePerformance) : '-'}</DateCell>
                <FundSizeCell>
                    <div className="text">{props.fundSize ? props.fundSize : '-'}</div>
                    <ViewButton onClick={props.onModalToggle}>Get alerted</ViewButton>
                </FundSizeCell>
            </RowContainer>
        ) : (
            <MobileRowContainter>
                <FundName>
                    {props.name} {props.fundName && `, ${props.fundName}`}
                </FundName>
                <MobileMiddleRow>
                    <FundAvatar src={props.logo}></FundAvatar>
                    <MobileInvestmentsRow>
                        <GreyText>Investment performance</GreyText>
                        <PercentageRow>
                            <SeparatePerformanceCell isActive={props.activeTabId === 0}>
                                {props.oneYearPerformance ? `${props.oneYearPerformance}%` : '-'}{' '}
                                <span>1y</span>
                            </SeparatePerformanceCell>
    
                            <SeparatePerformanceCell isActive={props.activeTabId === 1}>
                                {props.threeYearsPerformance ? `${props.threeYearsPerformance}%` : '-'}{' '}
                                <span>3y (p.a.)</span>
                            </SeparatePerformanceCell>
    
                            <SeparatePerformanceCell isActive={props.activeTabId === 2}>
                                {props.fiveYearsPerformance ? `${props.fiveYearsPerformance}%` : '-'}{' '}
                                <span>5y (p.a.)</span>
                            </SeparatePerformanceCell>
                        </PercentageRow>
                    </MobileInvestmentsRow>
                </MobileMiddleRow>
                <BottomMobileRow>
                    <BottomMobileItem>
                        <GreyText>Date performance to</GreyText>
                        {props.datePerformance ? DateUtils.formatDate(props.datePerformance) : '-'}
                    </BottomMobileItem>
                    <BottomMobileItem>
                        <GreyText>Fund size (£m)</GreyText>
                        {props.fundSize ? props.fundSize : '-'}
                    </BottomMobileItem>
                    <BottomMobileItem>
                        <ViewButton onClick={props.onModalToggle}>Get alerted</ViewButton>
                    </BottomMobileItem>
                </BottomMobileRow>
            </MobileRowContainter>
        );
    }
}

const ValueText = props =>
    props.isSelected ? (
        <SelectedText>{props.children}</SelectedText>
    ) : (
        <RegularText>{props.children}</RegularText>
    );

export default withScreenWidth(FundRow);
