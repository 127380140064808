import React from 'react';
import { LogoContainer, LogoImage } from './style';
import { HoverableLink } from '..';
import { routes } from '../../../Constants/routes';

export const Logo = () => (
    <LogoContainer>
        <HoverableLink to={routes.HOME}>
            <LogoImage />
        </HoverableLink>
    </LogoContainer>
);
