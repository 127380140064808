import styled from 'styled-components';
import { Text } from '../../Layouts';
import { colorCodes } from '../../../Constants/colors';

// submit email
export const VerticalCenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    position: relative;

    ${props => {
        if (props.forModal) {
            return `
                width: 100%;
                height: 100%;
                background-color: white;
                padding: 20px;
            `;
        }
    }}
`;

export const MessageText = styled(Text)`
    margin: 12px 0;
    font-weight: bold;
    font-size: 16px;
    color: ${colorCodes.BLUE2};
    text-align: center;
`;

export const ActionText = styled(Text)`
    color: ${colorCodes.SECONDARY};
    font-size: 16px;
    text-align: center;
    font-weight: bold;
`;
