import React from 'react';
import { TogglerRow, Item } from './style';
import { HeaderRegularText, SortingTableContainer } from '../../Containers/CompareFunds/style';
import { sortingRanges } from '../../../BusinessLogic/Models/SortingsItemsModel';

export const TableSortingToggle = props => (
    <SortingTableContainer>
        <HeaderRegularText alignCenter={true}>
            Order table by investment performance over:
        </HeaderRegularText>
        <TogglerRow>
            {sortingRanges.map((item, index) => (
                <Item
                    key={item.id}
                    isActive={props.activeTabId === item.id}
                    className="item"
                    onClick={() => props.onChange(item.id)}
                >
                    {item.title}
                </Item>
            ))}
        </TogglerRow>
    </SortingTableContainer>
);
