import React from 'react';
import PropTypes from 'prop-types';

import {
    SubheaderContainer,
    SubheaderTextContainer,
    ImportantText,
    BottomSubheaderContainer,
    SubheaderTitle,
    SubheaderDisclaimer,
    DisclaimerLink
} from './style';
import { splitNumberWithCommas } from '../../../BusinessLogic/Handlers/Common';
import { routes } from '../../../Constants/routes';

const getAdditionalText = value => (
    <React.Fragment>
        and a one-off cash payment at retirement of <ImportantText>{value}</ImportantText>
    </React.Fragment>
);

export const Subheader = props => (
    <React.Fragment>
        <SubheaderContainer>
            <SubheaderTitle>Your pension</SubheaderTitle>
            <SubheaderDisclaimer>
                Please be aware these are estimates{' '}
                <DisclaimerLink to={routes.DISCLAIMER}>View disclaimer</DisclaimerLink>
            </SubheaderDisclaimer>
            <SubheaderTextContainer>
                Your estimated annual salary at retirement is{' '}
                <ImportantText>£{splitNumberWithCommas(props.salaryAtRetirement)}</ImportantText> in
                today's terms. You opted for an annual pension of {props.annualOpt}% of your final
                salary which is equal to{' '}
                <ImportantText>£{splitNumberWithCommas(props.annualPension)} </ImportantText>
                {props.oneOffCashPayment
                    ? getAdditionalText(`£${splitNumberWithCommas(props.oneOffCashPayment)}`)
                    : null}
            </SubheaderTextContainer>
        </SubheaderContainer>

        <BottomSubheaderContainer>
            <SubheaderTitle>Your life expectancy</SubheaderTitle>
            <SubheaderTextContainer>
                With the remaining amount in your pension pot and the amount of pension you have
                requested, it is expected to last until you are{' '}
                {props.pensionTillAge === 124 && 'over '}
                <ImportantText>
                    {props.pensionTillAge === 124 ? props.pensionTillAge - 1 : props.pensionTillAge}
                </ImportantText>
                <br />
                <br />
                Which is <ImportantText>{props.timeFromNow}</ImportantText> years after your
                selected retirement date.
                <br />
                <br />
                The life expectancy of someone of your age is{' '}
                <ImportantText>{props.lifeExpectancy}</ImportantText> and there's a 1 in 10 chance
                of you living until you are <ImportantText>{props.liveLonger}</ImportantText>
            </SubheaderTextContainer>
        </BottomSubheaderContainer>
    </React.Fragment>
);

Subheader.propTypes = {
    annualOpt: PropTypes.number.isRequired,
    annualPension: PropTypes.number.isRequired,
    lifeExpectancy: PropTypes.number.isRequired,
    liveLonger: PropTypes.number.isRequired,
    pensionPotAtRetirement: PropTypes.number.isRequired,
    pensionTillAge: PropTypes.number.isRequired,
    remainingPension: PropTypes.number.isRequired,
    salaryAtRetirement: PropTypes.number.isRequired,
    timeFromNow: PropTypes.number.isRequired,
    oneOffCashPayment: PropTypes.number
};
