import React from 'react';

export class ExternalLink extends React.Component {
    handleRedirectClick = () => (window.location.href = this.props.path);
    applyPropsToChildren = () =>
        React.Children.map(this.props.children, child =>
            React.cloneElement(child, { onClick: this.handleRedirectClick })
        );

    render() {
        return this.applyPropsToChildren();
    }
}
