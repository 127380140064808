import React from 'react';
import { connect } from 'react-redux';
import { CompareFundsHeader } from './Header';
import SubmitEmailBlock from '../../Components/SubmitEmailBlock';
import FundRow from '../../Components/FundRow';
import { MainContentContainer, MainContent, DisclaimerText } from './style';
import { ProvidersActionCreators } from '../../../Store/ActionCreators/ProvidersActionCreators';
import { TableHeader } from './TableHeader';
import { Spinner, PinkSpinner } from '../../Components/Spinners';
import { ModalActionCreators } from '../../../Store/ActionCreators/ModalActionCreaators';
import { ModalContainer } from '../Modal/';

class CompareFundsPage extends React.Component {
    state = {
        sortingRange: 0,
        scrollPosition: window.pageYOffset,
        contentPadding: false
    };

    componentDidMount() {
        this.props.getListOfProviders();
        window.addEventListener('scroll', this.handleScroll);
    }

    changeSortingRange = tabId => this.setState({ sortingRange: tabId });

    handleScroll = () => {
        this.setState({ scrollPosition: window.pageYOffset }, () => {
            if (this.state.scrollPosition > 254) this.setState({ contentPadding: true });
            else this.setState({ contentPadding: false });
        });
    };

    render() {
        return (
            <React.Fragment>
                {this.props.isModalOpen && (
                    <ModalContainer>
                        <SubmitEmailBlock onClose={this.props.toggleModal} forModal={true} />
                    </ModalContainer>
                )}
                <CompareFundsHeader
                    activeTabId={this.props.sortRange}
                    onChange={this.props.setSortRange}
                />
                <TableHeader
                    scrollPosition={this.state.scrollPosition}
                    activeTabId={this.props.sortRange}
                    onChange={this.props.setSortRange}
                />
                <MainContentContainer>
                    <MainContent hasPadding={this.state.contentPadding}>
                        {this.props.isLoading ? (
                            <PinkSpinner />
                        ) : (
                            this.props.providersList.map(item => (
                                <FundRow
                                    activeTabId={this.props.sortRange}
                                    onModalToggle={this.props.toggleModal}
                                    key={item.id}
                                    {...item}
                                />
                            ))
                        )}
                        <DisclaimerText>
                            *The above information is based on what we believe to be the default
                            fund used by each provider when members are at least 20 years from
                            retirement. 
                            <br />
                            Where Target Date funds are used by providers for
                            consistency the target date funds closest to 2040 have been chosen.
                            <br />
                            Performance figures are updated 30 days after the end of each quarter
                            and are sourced from what is publicly available.
                            <br />
                            Performance returns are assumed to be net of fees.
                            <br />
                            Where providers use Target Date funds a large
                            number of funds are used based on members expected retirement date, as
                            such the size of this fund does not directly compare with other
                            providers who use single funds for their default investment
                            arrangements. 
                            <br />
                            Key: NPA = Not Publicly Available
                        </DisclaimerText>
                    </MainContent>
                </MainContentContainer>
                <SubmitEmailBlock />
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ providers, modal }) => ({
    providersList: providers.providersList,
    isLoading: providers.statuses.providersListLoading,
    sortRange: providers.sortRange,
    isModalOpen: modal.isModalOpen
});

const mapDispatchToProps = dispatch => ({
    getListOfProviders: () => dispatch(ProvidersActionCreators.getListOfProvidersRequest()),
    setSortRange: value => dispatch(ProvidersActionCreators.changeSortRange(value)),
    toggleModal: () => dispatch(ModalActionCreators.toggleModalWindow())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompareFundsPage);
