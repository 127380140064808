import React from 'react';
import { LinksContainer, SocialLinks, LinkText } from './style';
import { HoverableContainer, HoverableLink } from '..';

import { externalLinks } from '../../../Constants/externalLinks';
import { routes } from '../../../Constants/routes';
import { Icon } from '../../Components/Icons';
import { ExternalLink } from '../../Components/ExternalLink';

export const FooterLinks = () => (
    <LinksContainer>
        <HoverableLink target={'_blank'} to={routes.DISCLAIMER}>
            <LinkText>Calculator disclaimer</LinkText>
        </HoverableLink>
        <HoverableLink target={'_blank'} to={routes.TERMS_OF_SERVICE}>
            <LinkText>Terms of Service</LinkText>
        </HoverableLink>
        <HoverableLink target={'_blank'} to={routes.PRIVACY_POLICY}>
            <LinkText>Privacy Policy</LinkText>
        </HoverableLink>

        <SocialLinks>
            <ExternalLink path={externalLinks.INSTAGRAM}>
                <HoverableContainer>
                    <Icon.Box size={'16x16'}>
                        <Icon.Instagram />
                    </Icon.Box>
                </HoverableContainer>
            </ExternalLink>

            <ExternalLink path={externalLinks.TWITTER}>
                <HoverableContainer>
                    <Icon.Box size={'16x16'}>
                        <Icon.Twitter />
                    </Icon.Box>
                </HoverableContainer>
            </ExternalLink>

            <ExternalLink path={externalLinks.FACEBOOK}>
                <HoverableContainer>
                    <Icon.Box size={'16x16'}>
                        <Icon.Facebook />
                    </Icon.Box>
                </HoverableContainer>
            </ExternalLink>
        </SocialLinks>
    </LinksContainer>
);
