import React from 'react';
import { InputElement } from '../../../Containers/Input';
import { PinkRoundedButton } from '../../../Layouts';
import { FormContainer, EmailInput, ButtonWrapper, LinksContainer, Link, ErrorText } from './style';
import { routes } from '../../../../Constants/routes';
import { Spinner } from '../../Spinners';

export const SubmitEmailForm = ({
    email,
    validationErrors,
    handleChange,
    handleSubmit,
    isSubmitButtonDisabled,
    isRequestProcessing
}) => (
    <FormContainer onSubmit={handleSubmit}>
        <InputElement
            placeholder={'Your email'}
            value={email}
            name={'email'}
            onChange={handleChange}
            inputComponent={EmailInput}
            errorComponent={ErrorText}
            errorMessage={validationErrors.email}
        />
        <LinksContainer>
            <Link to={routes.TERMS_OF_SERVICE}>Terms of service</Link>
            <Link to={routes.PRIVACY_POLICY}>Privacy policy</Link>
        </LinksContainer>
        <ButtonWrapper>
            <PinkRoundedButton disabled={isSubmitButtonDisabled} type={'submit'}>
                {isRequestProcessing ? <Spinner /> : 'Sign up & agree terms'}
            </PinkRoundedButton>
        </ButtonWrapper>
    </FormContainer>
);
