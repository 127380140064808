import styled from 'styled-components';
import { font } from '../../Assets/fonts';
import { NavLink, Link } from 'react-router-dom';
import { colorCodes } from '../../Constants/colors';

export const Text = styled.p`
    text-transform: ${props => (props.uppercase ? 'uppercase' : null)};
    font-size: ${props => {
        if (props.small) {
            return '12px';
        } else if (props.medium) {
            return '16px';
        } else if (props.large) {
            return '20px';
        } else if (props.fontSize) {
            return `${props.fontSize}px`;
        }

        if (props.size) {
            return props.size;
        }

        return '14px';
    }};
    font-weight: ${props => (props.weight ? props.weight : 'normal')};
    text-align: ${props => (props.center ? 'center' : null)};
    opacity: ${props => (props.opacity ? props.opacity : '1')};
    padding: ${props => (props.padding ? props.padding : null)};
    margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
    margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
    margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
    max-width: ${props => (props.maxWidth ? props.maxWidth : null)};
    text-overflow: ${props => (props.textOverflow ? props.textOverflow : null)};
    overflow: ${props => (props.overflow ? props.overflow : null)};
    cursor: ${props => (props.cursor ? props.cursor : null)};

    font-style: normal;
    font-stretch: normal;
    line-height: ${props => {
        if (props.lineHeight) {
            return props.lineHeight;
        }
        return 1.2;
    }};

    letter-spacing: 0px;

    color: ${props => (props.color ? props.color : colorCodes.WHITE)};
    font-family: ${props => (props.fontFamily ? font(props.fontFamily) : font('primary'))};

    text-align: ${props => {
        if (props.left) {
            return 'left';
        } else if (props.alignCenter) {
            return 'center';
        }
        return null;
    }};

    white-space: ${props => {
        if (props.nowrap) {
            return 'nowrap';
        } else if (props.wrapText) {
            return 'initial';
        } else {
            return null;
        }
    }};

    ${props => {
        if (props.hover) {
            return `
                &:hover {
                    ${props.hover}
                }
            `;
        }
    }};

    ${props => {
        if (props.upperFirestCharacter) {
            return `&::first-letter {
                text-transform: uppercase;
             }`;
        } else {
            return null;
        }
    }};

    transition: ${props => {
        if (props.transition) return props.transition;
    }};
`;

export const HeadingText = styled(Text)`
    font-weight: bold;
    font-size: 48px;

    @media (max-width: 630px) {
        font-size: 36px;
    }
`;

export const TruncatedText = styled(Text)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const BoldText = styled(Text)`
    word-break: break-all;
    opacity: 0.9;
    weight: bold;
`;

export const HoverableLink = styled(NavLink)`
    text-decoration: none !important;
    &:hover {
        opacity: 0.75;
    }
    transition: all 0.25s;
`;

export const HoverableOutsideLink = styled(Link)`
    text-decoration: none !important;
    &:hover {
        opacity: 0.75;
    }
    transition: all 0.25s;
`;

export const HoverableContainer = styled.div`
    &:hover {
        opacity: 0.75;
    }

    transition: all 0.25s;
    cursor: pointer;
`;

// button
export const Button = styled.button`
    cursor: pointer;
    &:hover {
        opacity: 0.75;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
    transition: all 0.25s;
    border: none;
    outline: none;
`;

export const PinkRoundedButton = styled(Button)`
    width: 100%;
    border-radius: 100px;
    height: 45px;
    background-color: ${colorCodes.SECONDARY};
    text-transform: uppercase;
    color: ${colorCodes.WHITE};
    font-size: 16px;

    display: flex;
    justify-content: center;
`;

// & > a {
//     font-family: ${fonts('normal')};
//     color: ${color('white')};
// }

// color: ${props => (props.color ? color(props.color) : color('white'))};
