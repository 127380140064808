export const maleLifeExpectancy = {
    0: { expectancy: 90, liveLonger: 105 },
    1: { expectancy: 90, liveLonger: 105 },
    2: { expectancy: 90, liveLonger: 105 },
    3: { expectancy: 90, liveLonger: 104 },
    4: { expectancy: 89, liveLonger: 104 },
    5: { expectancy: 89, liveLonger: 104 },
    6: { expectancy: 89, liveLonger: 104 },
    7: { expectancy: 89, liveLonger: 104 },
    8: { expectancy: 89, liveLonger: 104 },
    9: { expectancy: 89, liveLonger: 104 },
    10: { expectancy: 89, liveLonger: 104 },
    11: { expectancy: 89, liveLonger: 103 },
    12: { expectancy: 88, liveLonger: 103 },
    13: { expectancy: 88, liveLonger: 103 },
    14: { expectancy: 88, liveLonger: 103 },
    15: { expectancy: 88, liveLonger: 103 },
    16: { expectancy: 88, liveLonger: 103 },
    17: { expectancy: 88, liveLonger: 103 },
    18: { expectancy: 88, liveLonger: 103 },
    19: { expectancy: 88, liveLonger: 102 },
    20: { expectancy: 87, liveLonger: 102 },
    21: { expectancy: 87, liveLonger: 102 },
    22: { expectancy: 87, liveLonger: 102 },
    23: { expectancy: 87, liveLonger: 102 },
    24: { expectancy: 87, liveLonger: 102 },
    25: { expectancy: 87, liveLonger: 102 },
    26: { expectancy: 87, liveLonger: 102 },
    27: { expectancy: 87, liveLonger: 101 },
    28: { expectancy: 87, liveLonger: 101 },
    29: { expectancy: 87, liveLonger: 101 },
    30: { expectancy: 86, liveLonger: 101 },
    31: { expectancy: 86, liveLonger: 101 },
    32: { expectancy: 86, liveLonger: 101 },
    33: { expectancy: 86, liveLonger: 101 },
    34: { expectancy: 86, liveLonger: 101 },
    35: { expectancy: 86, liveLonger: 100 },
    36: { expectancy: 86, liveLonger: 100 },
    37: { expectancy: 86, liveLonger: 100 },
    38: { expectancy: 86, liveLonger: 100 },
    39: { expectancy: 86, liveLonger: 100 },
    40: { expectancy: 86, liveLonger: 100 },
    41: { expectancy: 86, liveLonger: 100 },
    42: { expectancy: 86, liveLonger: 100 },
    43: { expectancy: 85, liveLonger: 100 },
    44: { expectancy: 85, liveLonger: 99 },
    45: { expectancy: 85, liveLonger: 99 },
    46: { expectancy: 85, liveLonger: 99 },
    47: { expectancy: 85, liveLonger: 99 },
    48: { expectancy: 85, liveLonger: 99 },
    49: { expectancy: 85, liveLonger: 99 },
    50: { expectancy: 85, liveLonger: 99 },
    51: { expectancy: 85, liveLonger: 99 },
    52: { expectancy: 85, liveLonger: 99 },
    53: { expectancy: 85, liveLonger: 98 },
    54: { expectancy: 85, liveLonger: 98 },
    55: { expectancy: 85, liveLonger: 98 },
    56: { expectancy: 85, liveLonger: 98 },
    57: { expectancy: 85, liveLonger: 98 },
    58: { expectancy: 85, liveLonger: 98 },
    59: { expectancy: 85, liveLonger: 98 },
    60: { expectancy: 85, liveLonger: 98 },
    61: { expectancy: 85, liveLonger: 98 },
    62: { expectancy: 85, liveLonger: 98 },
    63: { expectancy: 86, liveLonger: 97 },
    64: { expectancy: 86, liveLonger: 97 },
    65: { expectancy: 86, liveLonger: 97 },
    66: { expectancy: 86, liveLonger: 97 },
    67: { expectancy: 86, liveLonger: 97 },
    68: { expectancy: 86, liveLonger: 97 },
    69: { expectancy: 86, liveLonger: 97 },
    70: { expectancy: 86, liveLonger: 97 },
    71: { expectancy: 87, liveLonger: 97 },
    72: { expectancy: 87, liveLonger: 97 },
    73: { expectancy: 87, liveLonger: 97 },
    74: { expectancy: 87, liveLonger: 97 },
    75: { expectancy: 88, liveLonger: 97 },
    76: { expectancy: 88, liveLonger: 97 },
    77: { expectancy: 88, liveLonger: 97 },
    78: { expectancy: 88, liveLonger: 97 },
    79: { expectancy: 89, liveLonger: 97 },
    80: { expectancy: 89, liveLonger: 97 },
    81: { expectancy: 89, liveLonger: 97 },
    82: { expectancy: 90, liveLonger: 97 },
    83: { expectancy: 90, liveLonger: 97 },
    84: { expectancy: 91, liveLonger: 97 },
    85: { expectancy: 91, liveLonger: 97 },
    86: { expectancy: 92, liveLonger: 98 },
    87: { expectancy: 92, liveLonger: 98 },
    88: { expectancy: 93, liveLonger: 98 },
    89: { expectancy: 94, liveLonger: 98 },
    90: { expectancy: 94, liveLonger: 99 },
    91: { expectancy: 95, liveLonger: 99 },
    92: { expectancy: 96, liveLonger: 99 },
    93: { expectancy: 96, liveLonger: 100 },
    94: { expectancy: 97, liveLonger: 100 },
    95: { expectancy: 98, liveLonger: 101 },
    96: { expectancy: 99, liveLonger: 102 },
    97: { expectancy: 99, liveLonger: 102 },
    98: { expectancy: 100, liveLonger: 103 },
    99: { expectancy: 103, liveLonger: 103 },
    100: { expectancy: 102, liveLonger: 104 }
};

export const femaleLifeExpectancy = {
    0: { expectancy: 92, liveLonger: 106 },
    1: { expectancy: 92, liveLonger: 106 },
    2: { expectancy: 92, liveLonger: 106 },
    3: { expectancy: 92, liveLonger: 106 },
    4: { expectancy: 92, liveLonger: 106 },
    5: { expectancy: 92, liveLonger: 105 },
    6: { expectancy: 92, liveLonger: 105 },
    7: { expectancy: 92, liveLonger: 105 },
    8: { expectancy: 92, liveLonger: 105 },
    9: { expectancy: 92, liveLonger: 105 },
    10: { expectancy: 91, liveLonger: 105 },
    11: { expectancy: 91, liveLonger: 105 },
    12: { expectancy: 91, liveLonger: 105 },
    13: { expectancy: 91, liveLonger: 104 },
    14: { expectancy: 91, liveLonger: 104 },
    15: { expectancy: 91, liveLonger: 104 },
    16: { expectancy: 91, liveLonger: 104 },
    17: { expectancy: 91, liveLonger: 104 },
    18: { expectancy: 90, liveLonger: 104 },
    19: { expectancy: 90, liveLonger: 104 },
    20: { expectancy: 90, liveLonger: 104 },
    21: { expectancy: 90, liveLonger: 104 },
    22: { expectancy: 90, liveLonger: 103 },
    23: { expectancy: 90, liveLonger: 103 },
    24: { expectancy: 90, liveLonger: 103 },
    25: { expectancy: 90, liveLonger: 103 },
    26: { expectancy: 90, liveLonger: 103 },
    27: { expectancy: 90, liveLonger: 103 },
    28: { expectancy: 89, liveLonger: 103 },
    29: { expectancy: 89, liveLonger: 103 },
    30: { expectancy: 89, liveLonger: 102 },
    31: { expectancy: 89, liveLonger: 102 },
    32: { expectancy: 89, liveLonger: 102 },
    33: { expectancy: 89, liveLonger: 102 },
    34: { expectancy: 89, liveLonger: 102 },
    35: { expectancy: 89, liveLonger: 102 },
    36: { expectancy: 89, liveLonger: 102 },
    37: { expectancy: 89, liveLonger: 102 },
    38: { expectancy: 89, liveLonger: 102 },
    39: { expectancy: 88, liveLonger: 102 },
    40: { expectancy: 88, liveLonger: 101 },
    41: { expectancy: 88, liveLonger: 101 },
    42: { expectancy: 88, liveLonger: 101 },
    43: { expectancy: 88, liveLonger: 101 },
    44: { expectancy: 88, liveLonger: 101 },
    45: { expectancy: 88, liveLonger: 101 },
    46: { expectancy: 88, liveLonger: 101 },
    47: { expectancy: 88, liveLonger: 101 },
    48: { expectancy: 88, liveLonger: 101 },
    49: { expectancy: 88, liveLonger: 100 },
    50: { expectancy: 88, liveLonger: 100 },
    51: { expectancy: 88, liveLonger: 100 },
    52: { expectancy: 88, liveLonger: 100 },
    53: { expectancy: 88, liveLonger: 100 },
    54: { expectancy: 88, liveLonger: 100 },
    55: { expectancy: 88, liveLonger: 100 },
    56: { expectancy: 88, liveLonger: 100 },
    57: { expectancy: 88, liveLonger: 100 },
    58: { expectancy: 88, liveLonger: 100 },
    59: { expectancy: 88, liveLonger: 99 },
    60: { expectancy: 88, liveLonger: 99 },
    61: { expectancy: 88, liveLonger: 99 },
    62: { expectancy: 88, liveLonger: 99 },
    63: { expectancy: 88, liveLonger: 99 },
    64: { expectancy: 88, liveLonger: 99 },
    65: { expectancy: 88, liveLonger: 99 },
    66: { expectancy: 88, liveLonger: 99 },
    67: { expectancy: 88, liveLonger: 99 },
    68: { expectancy: 88, liveLonger: 99 },
    69: { expectancy: 88, liveLonger: 99 },
    70: { expectancy: 88, liveLonger: 99 },
    71: { expectancy: 88, liveLonger: 99 },
    72: { expectancy: 89, liveLonger: 98 },
    73: { expectancy: 89, liveLonger: 98 },
    74: { expectancy: 89, liveLonger: 98 },
    75: { expectancy: 89, liveLonger: 98 },
    76: { expectancy: 89, liveLonger: 98 },
    77: { expectancy: 90, liveLonger: 98 },
    78: { expectancy: 90, liveLonger: 98 },
    79: { expectancy: 90, liveLonger: 98 },
    80: { expectancy: 90, liveLonger: 98 },
    81: { expectancy: 91, liveLonger: 98 },
    82: { expectancy: 91, liveLonger: 98 },
    83: { expectancy: 91, liveLonger: 98 },
    84: { expectancy: 92, liveLonger: 98 },
    85: { expectancy: 92, liveLonger: 99 },
    86: { expectancy: 93, liveLonger: 99 },
    87: { expectancy: 93, liveLonger: 99 },
    88: { expectancy: 94, liveLonger: 99 },
    89: { expectancy: 94, liveLonger: 99 },
    90: { expectancy: 95, liveLonger: 100 },
    91: { expectancy: 95, liveLonger: 100 },
    92: { expectancy: 96, liveLonger: 100 },
    93: { expectancy: 97, liveLonger: 101 },
    94: { expectancy: 97, liveLonger: 101 },
    95: { expectancy: 98, liveLonger: 102 },
    96: { expectancy: 99, liveLonger: 102 },
    97: { expectancy: 100, liveLonger: 103 },
    98: { expectancy: 100, liveLonger: 103 },
    99: { expectancy: 101, liveLonger: 104 },
    100: { expectancy: 102, liveLonger: 104 }
};
