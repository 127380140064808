import React from 'react';
import { connect } from 'react-redux';
import { validateSubmitEmailFields } from '../../../BusinessLogic/Validation/EmailForm';
import { AuthActionCreators } from '../../../Store/ActionCreators/AuthActionCreators';

class SubmitEmailFormContainer extends React.Component {
    state = {
        email: '',
        validationErrors: {}
    };

    handleInputChange = event => this.setState({ [event.target.name]: event.target.value });
    setValidationErrors = validationErrors => this.setState({ validationErrors });
    clearValidationErrors = () => this.setState({ validationErrors: {} });
    checkButtonDisabled = () => this.state.email === '';

    handleSubmit = event => {
        event.preventDefault();

        this.clearValidationErrors();
        const { validationErrors, ...fields } = this.state;
        const { errors, isValidationPassed } = validateSubmitEmailFields(fields);

        if (isValidationPassed) {
            this.props.submitEmail(fields);
        } else this.setValidationErrors(errors);
    };

    render() {
        const isSubmitButtonDisabled = this.checkButtonDisabled() || this.props.isEmailSubmitting;

        return this.props.renderForm({
            ...this.state,
            handleChange: this.handleInputChange,
            handleSubmit: this.handleSubmit,
            isSubmitButtonDisabled,
            isRequestProcessing: this.props.isEmailSubmitting
        });
    }
}

const mapStateToProps = ({ auth }) => ({
    isEmailSubmitting: auth.statuses.isEmailSubmitting
});

const mapDispatchToProps = dispatch => ({
    submitEmail: credentials => dispatch(AuthActionCreators.submitEmailRequest(credentials))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitEmailFormContainer);
