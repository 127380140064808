import React from 'react';
import { Icon } from '../Icons';

export const Spinner = () => (
    <span>
        <Icon.Box size={'32x32'}>
            <Icon.DefaultWhiteSpinner />
        </Icon.Box>
    </span>
);

export const PinkSpinner = () => (
    <span>
        <Icon.Box size={'64x64'}>
            <Icon.DefaultPinkSpinner />
        </Icon.Box>
    </span>
);
