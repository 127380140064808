import React from 'react';
import { emoji } from '../../../Constants/emojis';

export const EmojiFactory = ({ type }) => {
    switch (type) {
        case emoji.PARTY_POPPER:
            return (
                <span role="img" aria-label={emoji.PARTY_POPPER}>
                    🎉
                </span>
            );
        default:
            return null;
    }
};
