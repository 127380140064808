import validator from 'validator';
import { isEmpty } from '../../Handlers/Common';
import { submitEmailErrorMessages } from '../../../Constants/errorMessages';

export const validateSubmitEmailFields = data => {
    let errors = {};
    let fields = { ...data };

    fields.email = !isEmpty(data.email) ? data.email : '';

    // email
    if (validator.isEmpty(fields.email)) errors.email = submitEmailErrorMessages.EMAIL_REQUIRED;
    if (!validator.isEmail(fields.email)) errors.email = submitEmailErrorMessages.INVALID_EMAIL;

    return {
        errors,
        fields,
        isValidationPassed: isEmpty(errors)
    };
};
