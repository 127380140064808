import React from 'react';
import { PageContainer, TextContainer, OrderedList, ListItem } from './style';
import { SmallHeader } from '../../Layouts/SmallHeader';
import { HeadingText } from '../../Layouts';

export class Disclaimer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <PageContainer>
                <SmallHeader>
                    <HeadingText alignCenter={true}>Calculator disclaimers</HeadingText>
                </SmallHeader>
                <TextContainer>
                    <OrderedList>
                        <ListItem>
                            The pensions calculator should be considered only as an illustration to
                            help assist you with your retirement planning
                        </ListItem>
                        <br />
                        <ListItem>
                            The pensions calculator is only suitable for defined contribution
                            pension arrangements. There is an option to include the UK state pension
                            but the pensions calculator does not allow for any other retirement
                            benefits you may be entitled to (for example definied benefit pension
                            arrangements)
                        </ListItem>
                        <br />

                        <ListItem>
                            If you select 'include state pension' the pensions calculator assumes
                            you are entitled to the full UK state pension at your state pension age
                        </ListItem>
                        <br />

                        <ListItem>
                            The pensions calculator is based on economic assumptions which may or
                            may not be drawn out in practice
                        </ListItem>
                        <br />

                        <ListItem>
                            Investment returns can go up and down and it is unlikely that the
                            investment returns your earn will be exactly in-line with the those used
                            in the pensions calculator, particularly over shorter time periods.
                            However, over the long-term the calculations broadly reflect the type of
                            investment returns which can be expected from investing in a range of
                            asset classes
                        </ListItem>
                        <br />

                        <ListItem>
                            The annual allowance for tax free pension contributions is currently set
                            at £40,000. Over the long-term the pensions calculator assumes that this
                            will be increased in-line with inflation
                        </ListItem>
                        <br />

                        <ListItem>
                            If your annual contribution amount is in excess of the annual allowance
                            your contribution will be capped at the annual allowance
                        </ListItem>
                        <br />

                        <ListItem>
                            The maximum salary in the pensions calculator has been set at £150,000.
                            This is due to the tax treatment of pension contributions meaning the
                            treatment of tax relief on pensions contributions changes once your
                            total remuneration is in excess of £150,000. If your salary is in excess
                            of £110,000 and you would like an estimate of your pension benefits at
                            retirement please contact pensions penguin.
                        </ListItem>
                        <br />

                        <ListItem>
                            The lifetime allowance is currently set at £1,055,000 for the 2019/2020
                            tax year. Each year the lifetime allowance is increased in-line with
                            inflation.
                        </ListItem>
                        <br />

                        <ListItem>
                            In the event that your pension pot at retirement is in excess of the
                            lifetime allowance it is assumed that the amount in excess of the
                            lifetime allowance will pay the one-off tax charge of 55% and the
                            residual amount will be paid as cash along with your tax-free cash lump
                            sum (assuming you opted to take this).
                        </ListItem>
                        <br />
                        <ListItem>
                            The pensions calculator assumes that you keep your pension pot invested
                            in retirement and do not purchase an annuity
                        </ListItem>
                        <br />
                        <ListItem>
                            The assumed investment returns both before and in retirement are net of
                            all underlying fees charged by your pension provider
                        </ListItem>
                        <br />
                        <ListItem>
                            The Office of National Statistics website has been used to calculate
                            your life expectancy using your and sex details you provided
                        </ListItem>
                        <br />
                        <ListItem>
                            Your salary is expected to increase annual by inflation plus any
                            increases you specify in addition to this (the default is set at 1%).{' '}
                        </ListItem>
                        <br />
                        <ListItem>
                            If retirement your chosen level of pension and the state pension are
                            assumed to increase annual in-line with the inflation assumption
                        </ListItem>
                        <br />
                        <ListItem>
                            Pension contributions and payments are expected to be paid on average
                            midway through each year (which is an approximation for the monthly
                            payments which will likely be made in reality)
                        </ListItem>
                        <br />
                        <ListItem>
                            The pensions calculator has been set up based on current uk tax law.
                            This may be subject to change in future.{' '}
                        </ListItem>
                    </OrderedList>
                </TextContainer>
            </PageContainer>
        );
    }
}
