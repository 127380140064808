import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';

export const PageContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TextContainer = styled.div`
    padding: 40px 25px 60px 25px;
    min-width: 350px;
    max-width: 750px;
    color: ${colorCodes.GREY6};
    text-align: justify;
    font-size: 16px;

    & a {
        color: ${colorCodes.TERMS_LINK};
    }
`;

export const Head = styled.h1``;

export const OrderedList = styled.ol`
    counter-reset: item;
    list-style-type: none;
`;

export const ListItem = styled.li`
    display: block;
    position: relative;

    &:before {
        content: counter(item) ' ';
        counter-increment: item;
        position: absolute;
        left: -50px;
    }
`;
