// general
export const SET_SEX = 'SET_SEX';
export const SET_AGE = 'SET_AGE';
export const SET_RETIREMENT_AGE = 'SET_RETIREMENT_AGE';
export const SET_SALARY = 'SET_SALARY';
export const SET_CONTRIBUTION = 'SET_CONTRIBUTION';
export const SET_CURRENT_PENSION_POT = 'SET_CURRENT_PENSION_POT';
export const SET_DESIRED_PENSION = 'SET_DESIRED_PENSION';

// advanced
export const SET_TAX_FREE_CASH_ALLOWANCE = 'SET_TAX_FREE_CASH_ALLOWANCE';
export const SET_INFLATION_RATE = 'SET_INFLATION_RATE';
export const SET_ANNUAL_SALARY_INCREASE_RATE = 'SET_ANNUAL_SALARY_INCREASE_RATE';
export const SET_INCLUDE_STATE_PENSION = 'SET_INCLUDE_STATE_PENSION';
export const SET_PRE_RETIREMENT_INVESTMENT_RETURN_RATE = 'SET_INVESTMENT_RETURN_RATE';
export const SET_POST_RETIREMENT_INVESTMENT_RETURN_RATE = 'SET_POST_RETIREMENT_INVESTMENT_RETURN_RATE';
