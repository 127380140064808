import { maleLifeExpectancy, femaleLifeExpectancy } from '../../../Data/lifeExpectancy';
import { calculatorFormulaConstantValues } from '../../../Constants/calculator';

export const getInputValueWithPoundSymbol = value => (value[0] === '£' ? value : `£${value}`);

export const getInputValueWithPercentSymbol = value =>
    value[value.length - 1] === '%' && value[value.lenght - 2] !== '%' ? value : `${value}%`;

export const handleCurrencyInput = (val, previousValueLength) => {
    let value = val.toString();
    value = value.replace(',', '');

    switch (true) {
        case value[0] === '£': {
            let output = value.slice(1, value.length);
            return checkIfConvertableToNumber(output)
                ? { isValid: true, output: +output }
                : { isValid: false };
        }
        case !checkIfSpecialSymbolTypedTwice(value, '£'):
            return { isValid: true, output: value };
        default:
            return { isValid: false };
    }
};

export const getNumberFromCurrencyValue = value => {
    if (typeof value === 'number') return value;
    return value.replace(',', '');
    // console.log(value.replace(',', ''));
};

export const handlePercentageInput = value => {
    switch (true) {
        case value[value.length - 1] === '%': {
            let output = value.slice(0, value.length - 1);
            return checkIfConvertableToNumber(output)
                ? { isValid: true, output: +output }
                : { isValid: false };
        }
        case !checkIfSpecialSymbolTypedTwice(value, '%'):
            return { isValid: true, output: value };
        default:
            return { isValid: false };
    }
};

const checkIfConvertableToNumber = value => !isNaN(+value) && !value.split('').includes('e');
const checkIfSpecialSymbolTypedTwice = (value, specialSymbol) =>
    value
        .toString()
        .split('')
        .includes(specialSymbol);

export const calculateUIOffsetForProgressBarTrack = value => {
    if (value >= 0 && value < 8) return { trackWidth: value * 2 };
    else if (value >= 8 && value < 16) return { trackWidth: value * 2 + 1 };
    else if (value >= 16 && value < 24) return { trackWidth: value * 2 + 2 };
    else if (value >= 24 && value < 32) return { trackWidth: value * 2 + 3 };
    else if (value >= 32 && value < 39) return { trackWidth: value * 2 + 4 };
    else if (value >= 39 && value < 47) return { trackWidth: value * 2 + 5 };
    else if (value >= 47 && value < 54) return { trackWidth: value * 2 + 6 };
    else if (value >= 54 && value < 62) return { trackWidth: value * 2 + 7 };
    else if (value >= 62 && value < 70) return { trackWidth: value * 2 + 8 };
    else if (value >= 70 && value < 77) return { trackWidth: value * 2 + 9 };
    else if (value >= 77 && value < 85) return { trackWidth: value * 2 + 10 };
    else if (value >= 85 && value < 93) return { trackWidth: value * 2 + 11 };
    else if (value >= 93 && value < 100) return { trackWidth: value * 2 + 12 };
    else if (value === 100) return { trackWidth: value * 2 + 13 };
};

/*
    Converts user inputs to the values required for calculations
*/
const getRawUserData = userInputs => ({
    inflationRate: userInputs.inflationRate / 100,
    annualSalaryIncreaseRate: userInputs.annualSalaryIncreaseRate / 100,
    desiredPension: userInputs.desiredPension / 100,
    contribution: userInputs.contribution / 100,
    preRetirementInvestmentReturnRate: userInputs.preRetirementInvestmentReturnRate / 100,
    postRetirementInvestmentReturnRate: userInputs.postRetirementInvestmentReturnRate / 100
});

/*
    Grabs the life expectancy data according to sex and age
*/
const getLifeExpectancyData = (sex, age) => {
    switch (sex) {
        case 0:
            return maleLifeExpectancy[age];
        case 1:
            return femaleLifeExpectancy[age];
        default:
            return maleLifeExpectancy[age];
    }
};

/*
    Calculates how many years left to the retirement according to a current age
    and a retirement age provided
*/
const calculateNumberOfYearsToRetirement = (retirementAge, currentAge) =>
    retirementAge - currentAge;

/*
    Calculates salary depending on the inflation rate and salary increase rate
    any years from now
*/
const calculateSalaryAtPatricularAge = (salary, inflation, salaryIncrease, yearsFromNow) =>
    salary * Math.pow(1 + inflation + salaryIncrease, yearsFromNow - 1);

/*
    Calculates any value in todays terms
*/
const calculateValueInTodaysTerms = (value, inflation, yearsFromNow) =>
    value / Math.pow(1 + inflation, yearsFromNow - 1);

// discuss later
const calculatePensionPotInTodaysTerms = (value, inflation, yearsFromNow) =>
    value / Math.pow(1 + inflation, yearsFromNow);

/*
    Calculates annual pension according to the salary at retirement and the desired pension
*/
const calculateAnnualPension = (salaryAtRetirement, desiredPensionPercentage) =>
    salaryAtRetirement * desiredPensionPercentage;

/*
    Used in the drawdown process
    Calculates total income drawn
*/
const calculateTotalIncomeDrawn = (salary, desiredPension, inflation, age, retirementAge) => {
    const initialDrawn = salary * desiredPension;
    return initialDrawn * Math.pow(1 + inflation, age - retirementAge);
};

/*
    Calculates total contribution 
*/
const calculateTotalContribution = (salary, contribution) => salary * contribution;

/*
    Calculates pot value at retirement
*/
const calculatePotValueAtRetirement = (
    currentPot,
    salary,
    inflation,
    contribution,
    preRetirementInvestment,
    salaryIncrease,
    yearsFromNow
) => {
    let result = currentPot;

    for (let index = 1; index < yearsFromNow + 1; index++) {
        const salaryAtCurrentPoint = calculateSalaryAtPatricularAge(
            salary,
            inflation,
            salaryIncrease,
            index
        );

        const contributionAtCurrentPoint = calculateTotalContribution(
            salaryAtCurrentPoint,
            contribution
        );

        result = (
            result * (1 + preRetirementInvestment) +
            contributionAtCurrentPoint * Math.pow(1 + preRetirementInvestment, 0.5)
        ).toFixed(2);
    }

    return result;
};

/*
    Used in the drawdown process
    Calculates pension pot after retirement and returns an age when the money runs out
*/
const calculatePensionPotAfterRetirement = (
    salary,
    desiredPension,
    inflation,
    age,
    retirementAge,
    includeStatePension,
    postRetirementInvestment,
    amountInvested
) => {
    let yearIndex = retirementAge,
        moneyAmountLeft = amountInvested,
        // condition = true,
        netAmountRequired;

    for (yearIndex; yearIndex < 125; yearIndex++) {
        let incomeDrawn = calculateTotalIncomeDrawn(
            salary,
            desiredPension,
            inflation,
            yearIndex,
            retirementAge
        );

        if (includeStatePension) {
            const statePension = calculateStatePension(inflation, yearIndex);
            netAmountRequired = incomeDrawn - statePension;
        } else netAmountRequired = incomeDrawn;

        moneyAmountLeft =
            moneyAmountLeft * (1 + postRetirementInvestment) -
            netAmountRequired * Math.pow(1 + postRetirementInvestment, 0.5);

        if (moneyAmountLeft <= 0 || netAmountRequired < 0) break;
    }

    // infinite loop often occurs
    // while (condition) {
    //     let incomeDrawn = calculateTotalIncomeDrawn(
    //         salary,
    //         desiredPension,
    //         inflation,
    //         yearIndex,
    //         retirementAge
    //     );

    //     console.log(incomeDrawn, 'Income drawn');

    //     if (includeStatePension) {
    //         const statePension = calculateStatePension(inflation, yearIndex);
    //         netAmountRequired = incomeDrawn - statePension;
    //     } else {
    //         netAmountRequired = incomeDrawn;
    //     }

    //     console.log(netAmountRequired, 'Net amount required');

    //     moneyAmountLeft =
    //         moneyAmountLeft * (1 + postRetirementInvestment) -
    //         netAmountRequired * Math.pow(1 + postRetirementInvestment, 0.5);

    //     console.log(moneyAmountLeft, 'Money amount left');

    //     if (moneyAmountLeft <= 0 || netAmountRequired < 0) {
    //         condition = false;
    //     } else yearIndex++;
    // }
    return yearIndex > 123 ? 124 : yearIndex;
};

/*
    Calculates tax free cash according to the pot value at retirement and checks
    if the lifetime allowance is exceeded
*/
const calculateTaxFreeCash = (
    potValueAtRetirement,
    includeTaxFreeCash,
    isLifetimeAllowanceExceeded
) => {
    if (includeTaxFreeCash) {
        if (isLifetimeAllowanceExceeded)
            return (
                calculatorFormulaConstantValues.LIFETIME_ALLOWANCE *
                calculatorFormulaConstantValues.TAX_FREE_CASH_PERCENTAGE
            );
        return potValueAtRetirement * calculatorFormulaConstantValues.TAX_FREE_CASH_PERCENTAGE;
    }

    return 0;
};

/*
    Calculates state pension in a particular age
*/
const calculateStatePension = (inflation, age) => {
    if (age >= calculatorFormulaConstantValues.STATE_PENSION_AGE) {
        const power = age - calculatorFormulaConstantValues.STATE_PENSION_AGE;
        return (
            calculatorFormulaConstantValues.STATE_PENSION_ASSUMPTION *
            Math.pow(1 + inflation, power)
        );
    }

    return 0;
};

/*
    Calculates remaining pension and one-off cash payment
*/
const calculateTotalValue = (potValue, taxFreeCashAllowance) => {
    let oneOffCashPayment, remaining;
    const isLifetimeAllowanceExceeded =
        potValue > calculatorFormulaConstantValues.LIFETIME_ALLOWANCE;

    const taxFreeCash = calculateTaxFreeCash(
        potValue,
        taxFreeCashAllowance,
        isLifetimeAllowanceExceeded
    );

    let remainingPension = potValue - taxFreeCash;

    if (potValue > calculatorFormulaConstantValues.LIFETIME_ALLOWANCE) {
        remaining = calculatorFormulaConstantValues.LIFETIME_ALLOWANCE - taxFreeCash;
        const exceedingAmount = potValue - calculatorFormulaConstantValues.LIFETIME_ALLOWANCE;
        const taxCharged = exceedingAmount * calculatorFormulaConstantValues.ONE_OFF_CHARGE;
        const amountLeftAfterTaxCharge = exceedingAmount - taxCharged;
        const cashAmount = amountLeftAfterTaxCharge + taxFreeCash;
        remainingPension = remaining;
        oneOffCashPayment = cashAmount;

        return {
            oneOffCashPayment,
            remainingPension
        };
    }

    oneOffCashPayment = taxFreeCash;

    return {
        oneOffCashPayment,
        remainingPension
    };
};

/*
    Accumulation process
*/
const handleAccumulationProcess = data => {
    // get life expectancy data
    const { expectancy, liveLonger } = getLifeExpectancyData(data.sex, data.age);

    // years left from now to the planned retirement
    const yearsFromNow = calculateNumberOfYearsToRetirement(data.retirementAge, data.age);

    // salary at retirement age
    const salaryAtRetirementAge = Math.round(
        calculateSalaryAtPatricularAge(
            data.salary,
            data.inflationRate,
            data.annualSalaryIncreaseRate,
            yearsFromNow
        )
    );

    // salary at retirement age in today's terms
    const salaryAtRetirementInTodaysTerms = Math.round(
        calculateValueInTodaysTerms(salaryAtRetirementAge, data.inflationRate, yearsFromNow)
    );

    // annual pension in today's terms
    const annualPensionInTodaysTerms = Math.round(
        calculateAnnualPension(salaryAtRetirementInTodaysTerms, data.desiredPension)
    );

    // pension pot at retirement
    const pensionPotAtRetirementAge = calculatePotValueAtRetirement(
        data.currentPensionPot,
        data.salary,
        data.inflationRate,
        data.contribution,
        data.preRetirementInvestmentReturnRate,
        data.annualSalaryIncreaseRate,
        yearsFromNow
    );

    // pension pot at retirement in today's terms
    const pensionPotAtRetirementAgeInTodaysTerms = Math.round(
        calculatePensionPotInTodaysTerms(
            pensionPotAtRetirementAge,
            data.inflationRate,
            yearsFromNow
        )
    );

    // total pension and tax free cash (also checks if lifetime allowance was exceeded)
    const { oneOffCashPayment, remainingPension } = calculateTotalValue(
        pensionPotAtRetirementAgeInTodaysTerms,
        data.taxFreeCashAllowance
    );

    return {
        lifeExpectancy: expectancy,
        liveLonger,
        salaryAtRetirement: salaryAtRetirementInTodaysTerms,
        annualOpt: data.desiredPension * 100,
        annualPension: annualPensionInTodaysTerms,
        pensionPotAtRetirement: pensionPotAtRetirementAgeInTodaysTerms,
        oneOffCashPayment: Math.round(oneOffCashPayment),
        remainingPension: Math.round(remainingPension)
    };
};

/*
    Drawdown process
*/
const handleDrawdownProcess = (data, totalPension) => {
    const pensionTillAge = calculatePensionPotAfterRetirement(
        data.salary,
        data.desiredPension,
        data.inflationRate,
        data.age,
        data.retirementAge,
        data.includeStatePension,
        data.postRetirementInvestmentReturnRate,
        totalPension
    );

    const timeFromNow =
        pensionTillAge === 124
            ? pensionTillAge - data.retirementAge - 1
            : pensionTillAge - data.retirementAge;

    return {
        pensionTillAge,
        timeFromNow
    };
};

/*
    Calculation of the output
*/
export const calculateResult = userInputs => {
    // preparing data
    const rawData = getRawUserData(userInputs);
    const data = { ...userInputs, ...rawData };

    // calculating accumulation data
    const accumulationResults = handleAccumulationProcess(data);

    // calculating drawdown data
    const drawdownResults = handleDrawdownProcess(data, accumulationResults.remainingPension);

    return {
        ...accumulationResults,
        ...drawdownResults
    };
};
