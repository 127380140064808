import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ErrorMessageBox = styled.div`
    min-width: 330px;
    max-width: 500px;
    padding: 0 50px;
    height: 200px;
    background-color: ${colorCodes.WHITE};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    border-radius: 10px;
    position: relative;
`;

export const CloseBox = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;

    cursor: pointer;
    transition: all 0.25s;

    &:hover {
        opacity: 0.75;
    }
`;
