export const investmentPerformanceSortingOptions = [
    {
        id: 0,
        label: '1y'
    },
    {
        id: 1,
        label: '3y (% p.a.)'
    },
    {
        id: 2,
        label: '5y (% p.a.)'
    }
];
