import styled from 'styled-components';
import { colorCodes } from '../../../../Constants/colors';

export const ActiveTrack = styled.div`
    width: ${props => (props.trackWidth ? `${props.trackWidth}px` : 0)};
    height: 8px;
    background-color: ${colorCodes.SECONDARY};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    position: absolute;
    left: 0;
    top: 11px;
    pointer-events: none;
`;

export const ValueDisplay = styled.output`
    width: 30px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: -37px;
    left: ${props => (props.xPos ? `${props.xPos - 5}px` : 0)};
    color: ${colorCodes.WHITE};
`;
