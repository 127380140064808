import * as types from '../ActionTypes/ModalActionTypes';

const initialState = {
    isModalOpen: false
};

export const ModalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.TOGGLE_MODAL_WINDOW: {
            // const openModalId = state.openModalId ? null : payload;

            return {
                ...state,
                isModalOpen: !state.isModalOpen
                // openModalId
            };
        }

        default:
            return state;
    }
};
