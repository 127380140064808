import styled from 'styled-components';
import { colorCodes, gradients } from '../../../Constants/colors';
import { Text } from '../../Layouts';

export const Content = styled.div`
    width: 100%;
    min-height: 317px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px 25px;
    justify-content: ${props => (props.emailSent ? 'center' : 'flex-start')};
    background: ${props => (props.emailSent ? colorCodes.white : gradients.PRIMARY)};
    position: relative;
    ${props => {
        if (props.forModal) {
            return `
                max-width: 700px;
                min-width: 300px;
            `;
        }
    }}
`;

export const CloseBox = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 20px;
    color: ${props => (props.color ? props.color : 'white')};

    cursor: pointer;
    transition: all 0.25s;

    &:hover {
        opacity: 0.75;
    }
`;

export const ComingSoonHeader = styled(Text)`
    font-size: 18px;
    color: ${colorCodes.WHITE};
    opacity: 0.3;
    text-transform: uppercase;
    font-weight: bold;
`;

export const MessageTextContainer = styled(Text)`
    min-width: 340px;
    max-width: 800px;
    font-size: 30px;
    line-height: 1.5;
    text-align: center;
    margin: 12px 0 21px 0;
    font-weight: bold;
    color: ${colorCodes.WHITE};

    ${props => {
        if (props.forModal) {
            return `
                max-width: 500px;
            `;
        }
    }}
`;
