export const sortingRanges = [
    {
        id: 0,
        title: '1 year'
    },
    {
        id: 1,
        title: '3 years'
    },
    {
        id: 2,
        title: '5 years'
    }
];

export const sortingRanges2 = [
    {
        id: 0,
        title: '1y'
    },
    {
        id: 1,
        title: '3y (% p.a.)'
    },
    {
        id: 2,
        title: '5y (% p.a.)'
    }
];
