import styled from 'styled-components';
import { colorCodes } from '../../../Constants/colors';
import { HeadingText, Text, Button } from '../../Layouts';

export const RegularText = styled(Text)`
    color: ${colorCodes.BLACK};
    font-weight: bold;

    & span {
        color: ${colorCodes.GREY10};
        margin-left: 5px;
    }

    @media (max-width: 880px) {
        font-size: 13px;
    }
`;

export const SelectedText = styled(Text)`
    color: ${colorCodes.SECONDARY};
    font-weight: bold;

    & span {
        color: ${colorCodes.GREY10};
        margin-left: 3px;
    }

    @media (max-width: 880px) {
        font-size: 13px;
    }
`;

export const DisclaimerText = styled(Text)`
    color: ${colorCodes.GREY10};
    width: calc(100% - 120px);
`;

export const ViewButton = styled(Button)`
    background-color: ${colorCodes.SECONDARY};
    color: ${colorCodes.WHITE};
    text-transform: uppercase;
    width: 100px;
    padding: 6px 3px;
    border-radius: 100px;
    margin-left: 15px;
    font-weight: bold;
`;

export const YearIndexRow = styled.div`
    display: flex;
    align-items: center;

    & .value {
        margin-right: 15px;
    }

    & .value:last-child {
        margin-right: 0;
    }
`;

// new
export const RowContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 35px 0;
    align-items: center;
    border-bottom: 1px solid ${colorCodes.GREY9};
`;

export const NameAvatarCell = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    padding: 0 8px;
`;

export const FundAvatar = styled.div`
    background-image: url(${props => (props.src ? props.src : null)});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 200px;
    width: 200px;
    max-width: 200px;
    height: 70px;

    @media (max-width: 990px) {
        margin-right: 10px;
        min-width: 75px;
        max-width: 75px;
        height: 40px;
    }
`;

export const FundName = styled(HeadingText)`
    color: ${colorCodes.GREY12};
    font-size: 18px;
    overflow: hidden;

    @media (max-width: 630px) {
        font-size: 18px;
    }
`;

export const PerformanceCell = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
`;

export const LeftPerformanceCell = styled.div`
    width: 33%;
`;

export const CenterPeformanceCell = styled.div`
    width: 33%;
    text-align: center;
`;

export const RightPerformanceCell = styled.div`
    width: 33%;
    text-align: right;
`;

export const SeparatePerformanceCell = styled.div`
    width: 33%;
    text-align: center;
    font-weight: bold;

    ${props => {
        if (props.isActive) {
            return `color: ${colorCodes.SECONDARY}`;
        }
    }}

    & span {
        font-size: 10px;
        color: ${colorCodes.GREY10};
    }
`;

export const DateCell = styled.div`
    width: 15%;
    padding: 0 8px;
    text-align: center;
    font-weight: bold;
`;

export const FundSizeCell = styled.div`
    width: 25%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-weight: bold;

    & .text {
        width: 30%;
        margin-right: 30px;
    }
`;

// mobile
export const MobileRowContainter = styled.div`
    width: 100%;
    padding: 16px 10px 32px 10px;
    margin-bottom: 16px;
    font-size: 13px;
    background-color: ${colorCodes.WHITE};
`;

export const MobileMiddleRow = styled.div`
    margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
`;

export const MobileInvestmentsRow = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const PercentageRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const GreyText = styled.p`
    color: ${colorCodes.GREY10};
    margin: 2px 0;
    font-weight: bold;
    text-align: center;
`;

export const BottomMobileRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const BottomMobileItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    margin: 0 4px;
`;
