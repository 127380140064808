export const calculatorFormulaConstantValues = {
    LIFETIME_ALLOWANCE: 1055000,
    ONE_OFF_CHARGE: 0.55,
    TAX_FREE_CASH_PERCENTAGE: 0.25,
    STATE_PENSION_ASSUMPTION: 8767.2,
    STATE_PENSION_AGE: 68,
    ANNUAL_CONTRIBUTION_ALLOWANCE: 40000
};

export const calculatorLimits = {
    MIN_ALLOWED_AGE: 18,
    MAX_ALLOWED_AGE: 100,

    MAX_ALLOWED_RETIREMENT_AGE: 75,

    MAX_ALLOWED_SALARY: 150000,
    MIN_ALLOWED_SALARY: 1000,

    MAX_ALLOWED_CONTRIBUTION: 20,
    MIN_ALLOWED_CONTRIBUTION: 1,

    MIN_ALLOWED_ANNUAL_PENSION: 1,
    MAX_ALLOWED_ANNUAL_PENSION: 100,

    MIN_ALLOWED_PENSION_POT: 0,

    MIN_ALLOWED_INFLATION: 0,
    MAX_ALLOWED_INFLATION: 10,

    MIN_ALLOWED_SALARY_INCREASE_RATE: 0,
    MAX_ALLOWED_SALARY_INCREASE_RATE: 4,

    MIN_ALLOWED_PRE_RETIREMENT_INVESTMENT_RETURN_RATE: 0,
    MAX_ALLOWED_PRE_RETIREMENT_INVESTMENT_RETURN_RATE: 10,

    MIN_ALLOWED_POST_RETIREMENT_INVESTMENT_RETURN_RATE: 0,
    MAX_ALLOWED_POST_RETIREMENT_INVESTMENT_RETURN_RATE: 6
};

export const calculatorFormulaInitialValues = {
    SEX: 0,
    AGE_TODAY: 32,
    PLANNED_RETIREMENT_AGE: 68,
    SALARY: 27000,
    TOTAL_CONTRIBUTION: 8,
    CURRENT_PENSION_POT: 12000,
    DESIRED_PENSION_AT_RETIREMENT: 66,
    TAX_FREE_ALLOWANCE: true,
    INFLATION_RATE: 3,
    ANNUAL_SALARY_INCREASE_RATE: 1,
    INCLUDE_STATE_PENSION: true,
    PRE_RETIREMENT_INVESTMENT_RETURN: 7,
    POST_RETIREMENT_INVESTMENT_RETURN: 5
};
