import styled from 'styled-components';
import { HeadingText, Text } from '../../Layouts';
import { colorCodes } from '../../../Constants/colors';
import MountainsBackground from '../../../Assets/Images/mountains-bg@3x.png';

// header
export const HeaderContent = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 50px;
    padding-bottom: 30px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const HeaderOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 183px;
    bottom: 0;
    left: 0;
    background-image: url(${MountainsBackground});
    background-size: cover;
`;

export const Heading = styled(HeadingText)`
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 12px;
    text-align: center;
`;

export const HeaderRegularText = styled(Text)`
    max-width: 540px;
    min-width: 350px;
    opacity: 0.8;
    line-height: 1.43;
    text-align: center;
`;

export const LeftHeaderContainer = styled.div`
    max-width: 600px;
`;

// content

export const MainContentContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    padding: 34px;

    @media (max-width: 768px) {
        padding: 10px 0;
        background-color: ${colorCodes.GREY7};
    }
`;

export const MainContent = styled.div`
    width: 85%;
    min-height: calc(100vh - 670px);
    display: flex;
    align-items: center;
    flex-direction: column;

    ${props => {
        if (props.hasPadding) {
            return `padding-top: 67px`;
        }
    }}

    & .fund-row {
        margin-bottom: 20px;
    }

    & .fund-row:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }
`;

// table header
export const TableHeaderContainer = styled.div`
    width: 100%;
    padding: 12px 0;
    background-color: ${colorCodes.GREY11};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 34px;

    ${props => {
        if (props.isSticked) {
            return `
                position: fixed;
                top: 0;
                left: 0;
            `;
        }
    }}

    @media (max-width: 880px) {
        display: none;
    }
`;

export const TableHeaderContent = styled.div`
    width: 85%;
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
        width: 100%;
    }
`;

export const TableItem = styled.div`
    font-size: 15px;
    width: ${props => (props.width ? props.width : '100%')};
    color: ${colorCodes.GREY12};
    ${props => {
        if (props.center) {
            return `
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;
                // padding-right: 30px;
            `;
        }
    }}
    font-weight: bold;
    padding: 0 8px;
`;

export const SeparatePerformanceCell = styled.div`
    width: 33%;
    text-align: center;
    // font-size: 16px;
`;

export const ToggleRow = styled.div`
    margin-top: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ToggleItem = styled.div`
    font-size: 13px;
    ${props => {
        if (props.isSelected) {
            return `
                color: ${colorCodes.SECONDARY};
            `;
        }
    }}

    & span {
        font-size: 8px;
    }

    cursor: pointer;
    text-align: center;
    transition: all 0.25s;
    &:hover {
        opacity: 0.75;
    }
`;

export const SortingTableContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const DisclaimerText = styled.p`
    color: ${colorCodes.GREY13};
    text-align: left;
    margin-top: 36px;

    @media (max-width: 768px) {
        padding: 0 1rem;
        margin-top: 0;
    }
`;
