import React from 'react';
import {
    TableHeaderContainer,
    TableHeaderContent,
    TableItem,
    ToggleRow,
    SeparatePerformanceCell
} from './style';
import { investmentPerformanceSortingOptions } from '../../../BusinessLogic/Models/FakeHelperModels';
import { SortingItem } from './SortingItem';

export class TableHeader extends React.PureComponent {
    render() {
        return (
            <TableHeaderContainer isSticked={this.props.scrollPosition >= 254}>
                <TableHeaderContent>
                    <TableItem width={'30%'}>Provider & name of fund</TableItem>
                    <TableItem center={true} width={'30%'}>
                        Investment performance
                        <ToggleRow>
                            {investmentPerformanceSortingOptions.map(item => (
                                <SeparatePerformanceCell>
                                    <SortingItem
                                        onClick={() => this.props.onChange(item.id)}
                                        isSelected={this.props.activeTabId === item.id}
                                        label={item.label}
                                    />
                                </SeparatePerformanceCell>
                            ))}
                        </ToggleRow>
                    </TableItem>
                    <TableItem center={true} width={'15%'}>
                        Date performance to
                    </TableItem>
                    <TableItem width={'25%'}>Fund Size (£m)</TableItem>
                </TableHeaderContent>
            </TableHeaderContainer>
        );
    }
}
