import * as types from '../ActionTypes/AuthActionTypes';
import * as errorTypes from '../ActionTypes/ErrorActionTypes';

const initialState = {
    hasError: false
};

export const ErrorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SUBMIT_EMAIL_FAILED: {
            return {
                ...state,
                hasError: true
            };
        }

        case errorTypes.CLEAR_ALL_ERRORS: {
            return {
                ...state,
                hasError: false
            };
        }

        default:
            return state;
    }
};
