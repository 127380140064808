import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
// import { composeWithDevtools } from 'redux-devtools-extension';

import { sagas } from './Sagas';
import reducer from './Reducers';

const sagaMiddleware = createSagaMiddleware();
const reduxLogger = createLogger();

export const store = createStore(reducer, compose(applyMiddleware(sagaMiddleware, reduxLogger)));

sagaMiddleware.run(sagas);
