import axios from 'axios';
import * as types from '../ActionTypes/AuthActionTypes';
import { put, call, takeLatest } from 'redux-saga/effects';
import { AuthActionCreators } from '../ActionCreators/AuthActionCreators';
import { auth } from '../../BusinessLogic/Network/API/Auth';

export class AuthSaga {
    static *submitEmail({ meta: { credentials } }) {
        try {
            const authApi = auth.create();
            yield call(axios, authApi.submitEmail(credentials));
            yield put(AuthActionCreators.submitEmailSuccess());
        } catch (error) {
            yield put(AuthActionCreators.submitEmailFailed(error));
        }
    }
}

export function* saga() {
    yield takeLatest(types.SUBMIT_EMAIL_REQUEST, AuthSaga.submitEmail);
}
