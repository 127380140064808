import { path } from '../../Paths';
import { requestMethods } from '../../../../Constants/network';
import { requestFactory } from '../../RequestFactory';

export const getListOfProviders = () => {
    const url = path.create().getListOfProviders();

    const requestConfig = {
        url,
        method: requestMethods.GET
    };

    return requestFactory.createRequest(requestConfig);
};
