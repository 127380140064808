import { path } from '../../Paths';
import { requestMethods } from '../../../../Constants/network';
import { toJSON } from '../../../Handlers/Common';
import { requestFactory } from '../../RequestFactory';

export const submitEmail = credentials => {
    const url = path.create().submitEmail();

    const requestConfig = {
        url,
        method: requestMethods.POST,
        data: toJSON(credentials)
    };

    return requestFactory.createRequest(requestConfig);
};
