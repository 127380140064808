import React, { Component } from 'react';
import { IconBox } from './style';

import { ReactComponent as Instagram } from '../../../Assets/Images/SVG/social-insta.svg';
import { ReactComponent as Facebook } from '../../../Assets/Images/SVG/social-facebook.svg';
import { ReactComponent as Twitter } from '../../../Assets/Images/SVG/social-twitter.svg';
import { ReactComponent as EmailSubmitted } from '../../../Assets/Images/SVG/email-submitted.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/Images/SVG/icon-close.svg';

import { ReactComponent as DefaultWhiteSpinner } from '../../../Assets/Images/SVG/Spinners/default-white-spinner.svg';
import { ReactComponent as DefaultPinkSpinner } from '../../../Assets/Images/SVG/Spinners/default-pink-spinner.svg';

export class Icon extends Component {
    static Box = props => <IconBox {...props} />;

    static Close = props => <CloseIcon {...props} />;
    static Instagram = props => <Instagram {...props} />;
    static Facebook = props => <Facebook {...props} />;
    static Twitter = props => <Twitter {...props} />;
    static EmailSubmitted = props => <EmailSubmitted {...props} />;
    static DefaultWhiteSpinner = props => <DefaultWhiteSpinner {...props} />;
    static DefaultPinkSpinner = props => <DefaultPinkSpinner {...props} />;

    render() {
        return null;
    }
}
