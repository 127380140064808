import axios from 'axios';
import * as types from '../ActionTypes/ProvidersActionTypes';
import { put, call, takeLatest } from 'redux-saga/effects';
import { providers } from '../../BusinessLogic/Network/API/Providers';
import { ProvidersActionCreators } from '../ActionCreators/ProvidersActionCreators';

export class ProvidersSaga {
    static *getListOfProviders() {
        try {
            const providersApi = providers.create();
            const { data: response } = yield call(axios, providersApi.getListOfProviders());
            yield put(ProvidersActionCreators.getListOfProvidersSuccess(response.data));
        } catch (error) {
            yield put(ProvidersActionCreators.getListOfProvidersFailed(error));
        }
    }
}

export function* saga() {
    yield takeLatest(types.GET_LIST_OF_PROVIDERS_REQUEST, ProvidersSaga.getListOfProviders);
}
