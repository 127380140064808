import React from 'react';
import { SalaryContainer, ActionButton, SalaryInput } from './style';
import {
    getInputValueWithPercentSymbol,
    getInputValueWithPoundSymbol
} from '../../../BusinessLogic/Handlers/Calculator';
import {
    handlePercentageInputSelection,
    handleCurrencyInputSelection,
    splitNumberWithCommas
} from '../../../BusinessLogic/Handlers/Common';

export class DefaultToggler extends React.Component {
    render() {
        const { onIncrease, onDecrease, onFocus, ...props } = this.props;
        return (
            <SalaryContainer>
                <ActionButton onClick={onDecrease}>-</ActionButton>
                <SalaryInput
                    {...props}
                    onFocus={e => {
                        props.currency
                            ? handleCurrencyInputSelection(e)
                            : handlePercentageInputSelection(e);
                    }}
                    value={
                        props.currency
                            ? getInputValueWithPoundSymbol(splitNumberWithCommas(props.value))
                            : getInputValueWithPercentSymbol(props.value)
                    }
                    onChange={e => props.handleChange(e.target.value)}
                />
                <ActionButton onClick={onIncrease}>+</ActionButton>
            </SalaryContainer>
        );
    }
}
