import styled from 'styled-components';
import { gradients } from '../../../Constants/colors';

export const HeaderWrapper = styled.div`
    width: 100%;
    min-height: 190px;
    background: ${gradients.PRIMARY};
    padding-top: 66px;
    position: relative;
`;
