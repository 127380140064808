import { submitEmail } from './requestConfigs';

class AuthApi {
    submitEmail = submitEmail;
}

class AuthFactory {
    auth = AuthApi;
    create() {
        return new this.auth();
    }
}

export const auth = new AuthFactory();
